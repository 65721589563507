/**
 * Created by Hp on 03/05/2017.
 */
(function () {

    module.exports = sequenceService;

    sequenceService.$inject = ["mnWebSocket", "$q", "$http"];

    function sequenceService(mnWebSocket, $q, $http) {
        let self = this;

        self.getAvailableSequence = getAvailableSequence;
        self.saveSequence = saveSequence;
        self.getSequences = getSequences;
        self.checkDependencies = checkDependencies;
        self.getSequenceModels = getSequenceModels;
        self.getDefaultSequenceModels = getDefaultSequenceModels;


        function getAvailableSequence(query) {
            return mnWebSocket.call('shared.Sequence.get_seq_by_model', query);
        }

        function saveSequence(sequence) {
            let deferred = $q.defer();
            let url = "/api/sequence/";
            if (!_.isNil(sequence.id)) url += sequence.id + "/";
            $http.post(url, sequence)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getSequences() {
            let deferred = $q.defer();
            let url = "/api/sequence/";
            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function checkDependencies(seq) {
            return mnWebSocket.call('shared.Sequence.check_dependencies', seq);
        }

        function getSequenceModels(query) {
            return mnWebSocket.call('shared.Sequence.get_sequence_models', query);
        }

        function getDefaultSequenceModels(query) {
            return mnWebSocket.call('shared.Sequence.get_default_sequence_models', query);
        }

    }

})();