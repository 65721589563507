/**
 * Created by amine on 04/04/2018.
 */
(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");
    const download = require('downloadjs');
    const BACKUP_ERROR_DIALOG = require('parameters/dialogs/app-backup-error-dialog');
    const VERIFICATION_DIALOG = require("auth/dialogs/auth-verification-dialog");

    class MaintenanceService {
        constructor($q, $http, authService, mnWebSocket, $mdDialog, $mdToast) {
            this.q = $q;
            this.http = $http;
            this.ws = mnWebSocket;
            this.authService = authService;
            this.dialog = $mdDialog;
            this.toast = $mdToast;

            this.isRoot = this.authService.isRootProfile();

            this._backupSubject = new BehaviorSubject(null);
            this._subscribed = false;
        }

        static get $inject() {
            return ["$q", "$http", "authService", "mnWebSocket", "$mdDialog", "$mdToast"];
        }

        fusePatients(primary, secondary) {
            return this.ws.call(
                "patient.PatientMinimal.fuse_patients", {
                    primary: _.get(primary, "id"), secondary: _.get(secondary, "id")
                }
            );
        }

        // tablet client related
        getTabletsClients() {
            const deferred = this.q.defer();
            const url = "/api/tablet-device/";

            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        handleTabletClient(config) {
            const deferred = this.q.defer();
            const url = `/api/tablet-device/${config.id ? config.id + '/' : ''}`;

            this.http.post(url, config)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        removeTabletClient(config) {
            const url = `/api/tablet-device/${config.id}/`;
            return this.http.delete(url);
        }

        getBackups() {
            const deferred = this.q.defer();

            this.http.get('/api/app-backup/').then(resp => {
                deferred.resolve(resp.data);
            }, deferred.reject);

            return deferred.promise;
        }

        getBackup(id) {
            const deferred = this.q.defer();

            this.http.get(`/api/app-backup/${id}/`).then(resp => {
                deferred.resolve(resp.data);
            }, deferred.reject);

            return deferred.promise;
        }

        saveBackup(backup) {
            const deferred = this.q.defer();

            this.http.post('/api/app-backup/', backup)
                .then(resp => {
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        reloadBackup(backup) {
            const deferred = this.q.defer();

            this.http.post(`/api/app-backup/${backup.id ? backup.id : backup}/reload/`, backup)
                .then(resp => {
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        downloadBackup(backup, direct = false) {
            const deferred = this.q.defer();

            let url = `/api/app-backup/${backup.id ? backup.id : backup}/download/`

            if (direct) url = `/api/app-backup/direct-download/?path=${backup}`;

            const promise = this.http.get(url, {responseType: 'arraybuffer'})
                .then(resp => {
                    if (!direct) backup.downloaded = true;

                    deferred.resolve(backup);
                    download(resp.data, resp.headers('x-filename'))
                }, deferred.reject);

            const toast = this.toast.mnAdvancedToast()
                .handle(promise)
                .label('app_backup.downloading');

            this.toast.show(toast);

            return deferred.promise;
        }

        deleteBackup(backup) {
            const deferred = this.q.defer();

            this.http.delete(`/api/app-backup/${backup.id ? backup.id : backup}/`)
                .then(resp => {
                    deferred.resolve(resp.data);
                }, deferred.reject);

            return deferred.promise;
        }

        backupsNotifications() {
            if (!this._subscribed) {
                this._subscribed = true;
                this.ws.sub("system.backup.AppBackup.backup_status", 'service', backup => {
                    if (backup === "backup_error") return this.internalErrorAlert();
                    else this.getBackup(backup.pk)
                        .then(data => {
                            const ownerId = _.get(data, "owner_id");
                            const staffId = _.get(this.authService, "staff.id");

                            if (this.isRoot || (ownerId && ownerId === staffId)) this._backupSubject.next(data)
                        });
                });
            }

            return this._backupSubject;
        }

        internalErrorAlert() {
            this.dialog.show(_.assign({}, BACKUP_ERROR_DIALOG, {
                locals: {},
            }))
        }

        serverShutdown(action) {
            const deferred = this.q.defer();

            this.dialog
                .show(_.assign({}, VERIFICATION_DIALOG))
                .then(pass => {
                    if (pass) {
                        this.http.post(`/api/system-${action}/`)
                            .then(resp => {
                                deferred.resolve(resp.data);
                            }, deferred.reject);
                    }
                }, deferred.reject);


            return deferred.promise;
        }
    }

    module.exports = MaintenanceService;

})();
