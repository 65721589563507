/**
 * Created by amine on 14/11/2017.
 */
(function () {

    'use strict';

    module.exports = MedicineSetupCtrl;

    MedicineSetupCtrl.$inject = ["$mdDialog", "$translate", "medicineService", "$q", "$scope"];

    function MedicineSetupCtrl($mdDialog, $translate, medicineService, $q, $scope) {
        let vm = this;
        const medicineDialog = require("shared/dialogs/medicine-form-dialog");

        vm.$onInit = init;
        vm.newMedicine = newMedicine;
        vm.editMedicine = editMedicine;
        vm.duplicateMedicine = duplicateMedicine;
        vm.removeMedicine = removeMedicine;
        vm.bookmarkMedicine = bookmarkMedicine;
        vm.disableMedicine = disableMedicine;

        vm.changeDatabase = changeDatabase;

        vm.onReorder = onReorder;
        vm.onPaginate = onPaginate;
        vm.getData = getData;

        function init() {
            vm.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };

            vm.columns = [];
            vm.options = [20, 15, 10, 5];

            vm.items = [];
            vm.total = 0;
            changeDatabase('mn');
        }

        function changeDatabase(db) {
            vm.query = {
                db: db,
                global_search: "",
                limit: 10,
                page: 1,
                order: "+commercial_name"
            };

            vm.total = 0;
            vm.items = [];
            $scope.$applyAsync(() => getData());
        }

        function onReorder(order) {
            vm.query = _.assign(vm.query, {order: order});
            getData();
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            vm.promise = $q.all([
                medicineService.getMedicinesList(vm.query),
                medicineService.getBookmarkedIdsList()
            ]).then(success);

            function success(data) {
                vm.total = data[0].length;
                vm.items = data[0].list;
                vm.bookmarked = data[1];

                if (vm.items < ((vm.query.page - 1) * vm.query.limit)) {
                    vm.query.page = 1;
                }
            }
        }

        function newMedicine($event, isPara = false) {
            $mdDialog.show(_.assign(medicineDialog, {
                targetEvent: $event,
                locals: {isPara}
            })).then(getData, _.noop);
        }

        function editMedicine(item, $event) {
            $mdDialog.show(_.assign(medicineDialog, {
                targetEvent: $event,
                locals: {
                    currentMedicine: item.id,
                    is_mn: item.is_mn,
                    isPara: item.is_para_product
                }
            })).then(getData, _.noop);
        }

        function duplicateMedicine(item, $event) {
            $mdDialog.show(_.assign(medicineDialog, {
                targetEvent: $event,
                locals: {
                    copy: item.id,
                    is_mn: item.is_mn
                }
            })).then(() => disableMedicine(item), _.noop);
        }

        function bookmarkMedicine(item, $event) {
            return medicineService.bookmarkMedicine(item).then(() => getData());

        }

        function disableMedicine(item, $event) {
            return medicineService.disableMedicine(item).then(newItem => {
                item.is_disabled = newItem.is_disabled;
            })
        }


        function removeMedicine(item, $event) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('medicine_remove_confirm', {title: item['commercial_name']}))
                .ariaLabel('remove plan confirm')
                .targetEvent($event)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                medicineService.removeMedicine(item).then(getData)
            }
        }
    }
})();
