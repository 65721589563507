/**
 * Created by BETALOS on 27/10/2016.
 */
(function () {

    'use strict';

    var mnSummaryItem = {
        controller: mnSummaryItemCtrl,
        controllerAs: "vm",
        bindings: {
            summary: '<'
        },
        template: '', // or template
    };

    mnSummaryItemCtrl.$inject = ['$element', '$compile', '$scope', '$sce'];

    function mnSummaryItemCtrl($element, $compile, $scope, $sce) {
        var vm = this;

        vm.$postLink = postLink;
        vm.trustAsHtml = trustAsHtml;


        function postLink() {
            var tpl = ''
            if (_.eq(vm.summary.type, 'field') && (_.isString(vm.summary.values) || _.isNil(vm.summary.values))) {
                tpl = require('patient/views/summary-string-field.tpl.html');
            }

            if (_.eq(vm.summary.type, 'field') && _.isArray(vm.summary.values)) tpl = require('patient/views/summary-field.tpl.html');
            if (_.eq(vm.summary.type, 'url-list')) tpl = require('patient/views/summary-url-list.tpl.html');

            $element.html(tpl);
            $compile($element.contents())($scope);
        }


        function trustAsHtml(string) {
            return $sce.trustAsHtml(string);
        }

    }

    module.exports = mnSummaryItem;

})();