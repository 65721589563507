(function () {

    'use strict';

    class PrismaOrderDialogCtrl {
        constructor($mdDialog, CloudPlatformsService, $q, $translate) {
            this.dialog = $mdDialog;
            this.CloudPlatformsService = CloudPlatformsService;
            this.q = $q;
            this.translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "CloudPlatformsService", "$q", "$translate"];
        }

        $onInit() {
            this.devices = [];
            this.assignedDevices = [];
            this.loadDevices();

        }

        loadDevices() {
            this.promise = this.q.all([this.CloudPlatformsService.getAssignedDevices(), this.CloudPlatformsService.getDevices()])
                .then(data => {
                    this.assignedDevices = data[0];
                    this.devices = data[1];
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        rejectDevice(p) {
            this.promise = this.CloudPlatformsService.assignDevice(_.get(p, 'deviceSn'), _.get(this.isAssigned(p), 'patient'), false)
                .then(data => {
                    if (!_.get(data, 'error')) {
                        this.loadDevices();
                        this.dialog.show(
                            this.dialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent(this.translate.instant(!_.get(data, 'error') ? "reject_device_success" : "reject_device_error"))
                                .ariaLabel(_.get(data, 'error'))
                                .ok(this.translate.instant('ok'))
                                .multiple(true)
                        );
                    }
                });
        }

        isAssigned(item) {
            return _.find(this.assignedDevices, (e) => {
                return e.sn == _.get(item, "deviceSn");
            });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PrismaOrderDialogCtrl,
        template: require("../views/prisma-devices-list-dialog.tpl.html"),
    };

})();
