(function () {

    'use strict';

    module.exports = SequenceCtrl;

    SequenceCtrl.$inject = ["$mdDialog", "sequenceService", "$mdToast", "$translate"];

    function SequenceCtrl($mdDialog, sequenceService, $mdToast, $translate) {
        var vm = this;
        vm.$onInit = init;
        vm.updateNextValue = updateNextValue;
        vm.updateSequence = updateSequence;
        vm.addSequence = addSequence;

        function init() {
            vm.sequences = [];
            loadSequences();
        }

        function loadSequences() {
            vm.promise = sequenceService.getSequences().then(success);

            function success(data) {
                vm.sequences = data;
            }
        }

        function updateSequence(item) {
            sequenceService.checkDependencies(item).then(success);

            function success(data) {
                if (!data) vm.promise = sequenceService.saveSequence(item).then(function (data) {
                    loadSequences();
                    $mdToast.show($mdToast.simple()
                        .textContent($translate.instant('sequence_edit_success'))
                        .position("bottom left")
                        .hideDelay(1500));
                });
                else showWarning();

            }
        }

        function updateNextValue(item) {
            if (!_.isNil(item.next_value)) {
                item.next_value = fillNumber(item['start_by'], item['filling']);
            }
        }

        function fillNumber(n, places) {
            var zero = places - n.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + n;
        }

        function showWarning() {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('sequence_operation_warn'))
                    .ariaLabel('confirm')
                    .ok($translate.instant('ok'))
            );
        }

        function addSequence(s) {
            $mdDialog.show(_.assign(require('parameters/dialogs/add-sequence-dialog'), {
                locals: {
                    models: _.chain(this.sequences).map( (s) => {
                        return {package: s.package, model_name: s.model_name, label:s.label}
                    }).uniqBy('model_name').value()
                }
            })).then(function (data) {
                loadSequences();
            });
        }

    }

})();