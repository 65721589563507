(function () {

    'use strict';

    const TEMPLATE_FIELDS_BAG = 'field-bag';
    const PATIENT_LINKS_BAG = 'patient-links-bag';

    const PATIENT_TABLET_CLIENT_DIALOG = require('../dialogs/patient-tablet-dialog');

    let links = require('../json/mobile-patient-tabs.json');

    const DRAG_CONFIG = {
        revertOnSpill: false,
        moves: (el, container, handle) => {
            return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
        }
    };

    class MobileAppCtrl {
        constructor(dragulaService, $scope, $q, configService, consultationService, $mdToast, $mdDialog, $translate, maintenanceService) {
            this.$q = $q;
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.configService = configService;
            this.consultationService = consultationService;
            this.maintenanceService = maintenanceService;

            dragulaService.options(this.$scope, PATIENT_LINKS_BAG, DRAG_CONFIG);
            dragulaService.options(this.$scope, TEMPLATE_FIELDS_BAG, DRAG_CONFIG);

            this.handleLinkOrder = _.mnDelay(() => this.handleLinkOrderFunc(), 400);
            this.handleLinkConfig = _.mnDelay(() => this.handleLinkConfigFunc(), 400);
            this.handleAccessConfig = _.mnDelay(() => this.handleAccessConfigFunc(), 400);

            const successToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            this.saveMsg = _.mnDelay(() => $mdToast.show(successToast), 200);
        }

        static get $inject() {
            return [
                'dragulaService', '$scope', '$q', 'configService', 'consultationService', '$mdToast', '$mdDialog',
                '$translate', 'maintenanceService'
            ];
        }

        $onInit() {
            this.$scope.$on(`${PATIENT_LINKS_BAG}.dragend`, () => this.handleLinkOrder());

            this.promise = this.$q.all([
                this.configService.get(["mobile_links_config", "mobile_access_config", "tablet_background"], true),
                this.consultationService.getMobileConsultationTemplate(),
                this.maintenanceService.getTabletsClients()
            ]).then(data => this.success(data));
        }

        success(data) {
            if (!this.configService.isDental()) _.remove(links, ['dental', true]);

            this.template = data[1];
            this.patientTablets = data[2];

            this.linkConfig = _.get(data, '0.mobile_links_config', {});
            this.accessConfig = _.get(data, '0.mobile_access_config', {});
            this.tabletBackground = _.get(data, '0.tablet_background', {});

            this.patientSubLinks = this.linkConfig.order ? _.orderBy(links, item => this.orderByFunc(item)) : links;
        }

        handleLinkOrderFunc() {
            this.linkConfig.order = _.reduce(
                this.patientSubLinks, (config, item, key) => _.assign({}, config, {[item.key]: key}), {}
            );

            this.handleLinkConfig();
        }

        handleLinkConfigFunc() {
            this.linkConfig.links = _.chain(this.patientSubLinks)
                .map('key')
                .filter(item => !_.get(this.linkConfig, `disabled.${item}`))
                .orderBy(item => this.orderByFunc(item))
                .value();

            this.configService.set({"mobile_links_config": this.linkConfig}, true)
                .then(() => this.saveMsg());
        }

        handleAccessConfigFunc() {
            if (!this.accessConfig['has_mobile']) {
                this.accessConfig = _.mapValues(this.accessConfig, () => false);
            }

            this.configService.set({"mobile_access_config": this.accessConfig})
                .then(() => this.saveMsg());
        }

        removeField(index) {
            this.template.fields.splice(index, 1);
        }

        addNewField() {
            this.template.fields.push({});
        }

        handleSave() {
            return this.consultationService.saveMobileConsultationTemplate(this.template)
                .then(data => this.template = data);
        }

        orderByFunc(item) {
            return _.get(this.linkConfig, `order['${item.key}']`);
        }

        // tablet related
        handleTablet(config, ev) {
            this.dialog.show(_.assign({}, PATIENT_TABLET_CLIENT_DIALOG, {
                targetEvent: ev,
                locals: {
                    config: _.cloneDeep(config)
                }
            })).then(data => this.patientTablets = _.pushOrUpdate(this.patientTablets, data));
        }

        removeTablet(config) {
            this.promise = this.maintenanceService.removeTabletClient(config)
                .then(() => {
                    this.patientTablets = _.without(this.patientTablets, config);
                });
        }

        // tablet background setup
        handleFiles(ev) {
            this.$scope.$applyAsync(() => {
                this.convertToBase64(ev[0])
                    .then(base => {
                        this.tabletBackground.background = base;
                        this.saveTabletBackground();
                    });
            });
        }

        saveTabletBackground() {
            this.configService.set({"tablet_background": this.tabletBackground})
                .then(() => this.saveMsg());
        }

        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
    }

    module.exports = MobileAppCtrl;

})();
