(function () {

    'use strict';

    module.exports = LocationCtrl;

    LocationCtrl.$inject = ["$q", "locationService", "$mdDialog"];

    function LocationCtrl($q, locationService, $mdDialog) {
        var vm = this;

        vm.cities = []
        vm.countries = []
        vm.provinces = []

        vm.$onInit = init;
        vm.handleItem = handleItem;
        vm.removeItem = removeItem;

        function init() {
            vm.promise = $q.all([
                locationService.list('City'),
                locationService.list('Country'),
                locationService.list('Province')
            ]).then(success);

            function success(data) {
                vm.cities = data[0];
                vm.countries = data[1];
                vm.provinces = data[2];
            }
        }

        function handleItem(item, model, ev) {
            var dialog = _.assign(require('shared/dialogs/location-dialog'), {
                targetEvent: ev,
                locals: {
                    mnModel: model,
                    location: _.cloneDeep(item),
                    mnLabel: model.toLowerCase(),
                }
            });

            $mdDialog.show(dialog).then(done);

            function done(data) {
                var modelItems = getModelItems(model);
                vm[modelItems] = _.pushOrUpdate(vm[modelItems], data);
            }
        }

        function removeItem(item, model, ev) {
            locationService.removeItem(item, model, ev)
                .then(success);

            function success() {
                var modelItems = getModelItems(model);
                _.pull(vm[modelItems], item);
            }
        }

        function getModelItems(model) {
            if (model === "City") return "cities";
            if (model === "Country") return "countries";
            if (model === "Province") return "provinces";
        }

    }

})();