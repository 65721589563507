/**
 * Created by BETALOS on 22/02/2017.
 */
(function () {

    'use strict';

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: BiologyParameterDialogCtrl,
        template: require("../views/biology-parameter-dialog.tpl.html"),
    };

    BiologyParameterDialogCtrl.$inject = ["$mdDialog", "biologyService"];

    function BiologyParameterDialogCtrl($mdDialog, biologyService) {
        let vm = this;

        vm.$onInit = init;

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        vm.handleUnique = handleUnique;
        vm.removeInterval = removeInterval;
        vm.addInterval = addInterval;

        vm.testUnique = testUnique;

        function init() {
            if (vm.isParameter && _.isNil(vm.biologyExam)) {
                vm.biologyExam = {
                    settings: null,
                    details: []
                };

                vm.biologyExamForm = vm.biologyExam;
            } else if (_.isString(vm.uid)) {
                vm.biologyExamForm = _.find(vm.biologyExam.details, {uid: vm.uid});
            } else if (_.isNull(vm.uid)) {
                vm.biologyExamForm = {
                    settings: {
                        type: "float", intervals: _.castArray({})
                    }
                };
                vm.biologyExam.details =  _.chain(vm.biologyExam.details).concat(vm.biologyExamForm).compact().value();
            } else if (vm.biologyExam['is_unique']) {
                vm.biologyExamForm = vm.biologyExam;
            }

        }

        function handleUnique() {
            if (vm.biologyExamForm['is_unique']) {
                vm.biologyExamForm.settings = {
                    type: "float",
                    intervals: _.castArray({})
                }
            } else {
                vm.biologyExamForm.details = [];
                vm.biologyExamForm.settings = null;
                vm.biologyExamForm.ignore = true;
            }
        }

        function submit() {
            biologyService.handleBiologyExam(vm.biologyExam)
                .then($mdDialog.hide);
        }

        function removeInterval(index) {
            if (_.size(vm.biologyExamForm.settings['intervals']) == 1) vm.biologyExamForm.settings['intervals'] = _.castArray({});
            else vm.biologyExamForm.settings['intervals'].splice(index, 1);
        }

        function addInterval() {
            vm.biologyExamForm.settings['intervals'].push({});
        }

        function testUnique() {
            return (!!vm.biologyExamForm['is_unique'] || !_.isUndefined(vm.uid));
        }

    }

})();