(function () {

    'use strict';

    module.exports = ContactSetupCtrl;

    ContactSetupCtrl.$inject = ["$q", "physicianService", "contactService", "$mdDialog", "$translate"];

    function ContactSetupCtrl($q, physicianService, contactService, $mdDialog, $translate) {
        var vm = this;

        vm.$onInit = init;

        vm.handleContact = handleContact;
        vm.removePhysician = removePhysician;
        vm.removeContact = removeContact;

        function init() {
            $q.all([physicianService.getContacts(), contactService.getContacts()])
                .then(success);

            function success(data) {
                vm.physicians = data[0];
                vm.contacts = data[1];
            }
        }

        function handleContact(contact, ev) {
            $mdDialog.show(_.assign(require('shared/dialogs/contact-dialog'), {
                targetEvent: ev,
                locals: {
                    hasBooth: !contact,
                    contact: contact,
                    isPhysician: _.get(contact, '_model') == "PhysicianContact",
                }
            })).then(done);

            function done(data) {
                var key = _.eq(data['_model'], 'ReferringContact') ? 'contacts' : 'physicians';
                vm[key] = _.pushOrUpdate(vm[key], data);
                vm.model = data;
            }
        }

        function removePhysician(physician, ev) {
            var confirm = $mdDialog.confirm()
                .title($translate['instant']('contact_setup_physician_remove_confirm', {title: physician['full_name']}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = physicianService.removePhysicianContact(physician)
                    .then(done)
            }

            function done() {
                _.pull(vm.physicians, physician);
            }
        }

        function removeContact(contact, ev) {
            var confirm = $mdDialog.confirm()
                .title($translate['instant']('contact_setup_referring_remove_confirm', {title: contact['full_name']}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = contactService.removeContact(contact)
                    .then(done)
            }

            function done() {
                _.pull(vm.contacts, contact);
            }
        }

    }

})();