(function () {

    'use strict';

    class OximetryConfigCtrl {
        constructor() {

        }

        static get $inject() {
            return [];
        }

        $onInit() {

        }

    }


    module.exports = OximetryConfigCtrl;

})();