/**
 * Edited by Amine on 04/10/2021.
 */
(function () {

    'use strict';

    class CatalogService {
        constructor($http, $q) {
            this.http = $http;
            this.q = $q;
        }

        static get $inject() {
            return ["$http", "$q"];
        }

        saveCatalog(catalog) {
            let deferred = this.q.defer();
            let url = `/api/procedure-catalog/${!!catalog.id ? `${catalog.id}/` : '/'}`;

            this.http.post(url, catalog)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        deleteCatalog(catalog) {
            let deferred = this.q.defer();
            let url = `/api/procedure-catalog/${!!catalog.id ? `${catalog.id}/` : `${catalog}/`}`;

            this.http.delete(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getCatalogs() {
            let deferred = this.q.defer();
            let url = "/api/procedure-catalog/";

            this.http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        getCatalog(catalog) {
            let deferred = this.q.defer();
            let url = `/api/procedure-catalog/${!!catalog.id ? `${catalog.id}/` : `${catalog}/`}`;

            this.http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }
    }

    module.exports = CatalogService;

})();