/**
 * Created by BETALOS on 05/09/2016.
 */
(function () {

    'use strict';

    module.exports = measurement;

    measurement.$inject = ["$compile"];

    function measurement($compile) {
        let directive = {
            restrict: 'A',
            priority: 0,
            link: measurementLink,
        };

        function measurementLink(scope, element, attrs) {
            let measures = scope.$eval(attrs['mnMeasures']);
            let calculateFormula = require('shared/utils/calculate-formula');

            scope.$watch(attrs['mnMeasurement'], generateHtml);

            function generateHtml(measurement) {
                element.empty();

                element.append(
                    $('<td md-cell />').text(_.isObject(measurement.date) ? measurement.date.datetime : measurement.date)
                );

                _(measures).forEach(function (measure) {
                    let $td = $("<td md-cell class='md-cell' />");
                    let value = _.get(measurement, `measure_values.${measure.id}.value`, null);

                    if (measure.type === "float" && !_.isNull(value)) {
                        $td.text(value.toFixed(2));
                    }

                    if (measure.type === "boolean" && !_.isNull(value)) {
                        $td.append($("<span class='round-span'></span>").addClass(value ? 'green' : 'red'));
                    }

                    if (measure.type === "calculated") {
                        let calc = parseFloat(calculateFormula(measure['formula'], measurement, measures));
                        if (calc) $td.text(calc.toFixed(2));
                    }

                    if (_.includes(["integer", "string", 'date'], measure.type) && !_.isNull(value)) {
                        $td.text(value);
                    }

                    element.append($td);
                });

                element.append($('<td class="md-cell" />').text(measurement.comment));

                let $buttons = $('<td class="md-cell action-cell"/>');

                let $editButton = [
                    '<md-button class="md-icon-button" ng-click="vm.editMeasurement(measurement, $event)" aria-label="edit">',
                    '   <md-icon md-font-icon="mdi-pencil" md-font-set="mdi"></md-icon>',
                    '</md-button>'
                ].join('');

                let $removeButton = [
                    '<md-button class="md-icon-button" ng-click="vm.removeMeasurement(measurement)" aria-label="remove">',
                    '   <md-icon md-font-icon="mdi-delete" md-font-set="mdi"></md-icon>',
                    '</md-button>'
                ].join('');

                $buttons.append($editButton);
                $buttons.append($removeButton);

                element.append($buttons);

                $compile(element.contents())(scope);
            }


        }

        return directive;
    }

})()