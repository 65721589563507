/**
 * Created by Amine on 26/03/2021.
 */
(function () {

    'use strict';

    const GENERATE_DIALOG = require('../dialogs/app-backup-dialog');
    const ADV_CONFIG_DIALOG = require('../dialogs/app-backup-config-dialog');

    class AppBackupCtrl {
        constructor($mdDialog, $translate, $auth, $q, maintenanceService, configService, mnWebSocket) {
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.auth = $auth;
            this.q = $q;
            this.maintenanceService = maintenanceService;
            this.configService = configService;
            this.ws = mnWebSocket;

            this.promise = null;
            this.backups = [];
            this.paths = [];
            this.mainTab = true;

        }

        static get $inject() {
            return ["$mdDialog", "$translate", "$auth", "$q", "maintenanceService", "configService", "mnWebSocket"];
        }

        $onInit() {
            this.promise = this.q.all([
                this.maintenanceService.getBackups(),
                this.configService.getByHttp("backups_config"),
                this.ws.call("system.backup.AppBackup.read_paths", {})
            ]).then(data => {
                this.backups = data[0];
                this.paths = _.chain(data[1])
                    .get("paths", [])
                    .map(item => _.assign({}, item, {data: _.get(data[2], item.title, [])}))
                    .value();
            });

            this.subscription = this.ws.sub("system.backup.AppBackup.backup_status", 'setup', backup => {
                this.maintenanceService.getBackup(backup.pk)
                    .then(data => this.saveSuccess(data));
            });
        }

        $onDestroy() {
            this.ws.unsub("system.backup.AppBackup.backup_status", "setup");
        }

        newBackup(ev) {
            this.dialog.show(_.assign({}, GENERATE_DIALOG, {
                targetEvent: ev,
                locals: {},
            })).then(backup => {
                this.promise = this.maintenanceService.saveBackup(backup)
                    .then(data => this.saveSuccess(data));
            }, _.noop);
        }

        saveSuccess(data) {
            this.backups = _.pushOrUpdate(this.backups, data)
        }

        download(backup) {
            this.maintenanceService.downloadBackup(backup)
                .then(data => this.saveSuccess(data))
        }

        directDownload(backup) {
            this.maintenanceService.downloadBackup(backup.abs_path, true)
        }

        reload(backup) {
            this.maintenanceService.reloadBackup(backup)
                .then(data => this.saveSuccess(data))
        }

        remove(backup, ev) {
            const confirm = this.dialog.confirm()
                .title(this.translate.instant('app_backup.remove_confirm', backup))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.maintenanceService.deleteBackup(backup)
                        .then(() => _.remove(this.backups, backup));
                }, _.noop);
        }

        backupAdvConfig(ev) {
            this.dialog.show(_.assign({}, ADV_CONFIG_DIALOG, {
                targetEvent: ev,
                locals: {},
            })).then(backup => {
                // this.promise = this.maintenanceService.saveBackup(backup)
                //     .then(data => this.saveSuccess(data));
            }, _.noop);
        }
    }

    module.exports = AppBackupCtrl;

})();
