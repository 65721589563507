/**
 * Created by BETALOS on 03/05/2017. [edited by BETALOS 17/10/2019]
 */
(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const CONTACT_DIALOG = require('shared/dialogs/contact-dialog');

    class ReferringContactCtrl {
        constructor(
            physicianService, contactService, $timeout, $mdDialog, modalService, $scope, patientService,
            $q, $mdToast, $translate
        ) {
            this.q = $q;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.modalService = modalService;
            this.patientService = patientService;
            this.contactService = contactService;
            this.physicianService = physicianService;

            this.patients = [];
            this.contacts = [];
            this.physicians = [];
            this.initContacts = [];
            this.initPhysicians = [];

            this.choose = false;
            this.modalSubscription = null;
            this.newContactSubscription = null;

            this.internalValue = null;
            this.eventSubject = new Subject();

            this.patient = this.patient || this.patient;
            this.ngModelController = this.ngModelController || null;
            this.simpleToast = this.toast.simple()
                .hideDelay(1500)
                .position("bottom left")
                .textContent($translate.instant('patient_same_reference_or_this_patient_is_already_referenced_by_selected_patient'));
        }

        static get $inject() {
            return [
                "physicianService", "contactService", "$timeout", "$mdDialog", "modalService", "$scope",
                "patientService", "$q", "$mdToast", "$translate"
            ];
        }

        $onInit() {
            this.q.all([this.contactService.getContacts(), this.physicianService.getContacts()])
                .then(data => {
                    this.initContacts = _.sortBy(data[0], 'full_name');
                    this.initPhysicians = _.sortBy(data[1], 'full_name');

                    this.filter("");
                });

            this.modalSubscription = this.modalService.subject.subscribe(data => this.patientCallBack(data));
            this.newContactSubscription = this.physicianService.newContactSubject.subscribe(data => {
                this.initPhysicians = _.chain(this.initPhysicians)
                    .pushOrUpdate(data).sortBy('full_name').value();

                this.filter("");
            });

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onDestroy() {
            this.modalSubscription.unsubscribe();
            this.newContactSubscription.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue;
            }

            if (_.get(this.value, '_model', null) === 'Patient') {
                this.patients = _.castArray(this.value);
                this.value = _.assign(this.value, {track_by: 'patient'});
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }


        mdSelectEvent(event) {
            this.filter("");
            this.eventSubject.next(event);
        }

        cancel() {
            this.patients = [];
            this.internalValue = null;
            this.internalValueChanged();
        }

        filter(key) {
            this.contacts = this.filterItems(this.initContacts, key).concat(
                (this.internalValue && this.internalValue._model) === "ReferringContact" ? this.internalValue : null
            ).sortBy("full_name").uniqBy('id').compact().value();

            this.physicians = this.filterItems(this.initPhysicians, key).concat(
                (this.internalValue && this.internalValue._model) === "PhysicianContact" ? this.internalValue : null
            ).sortBy("full_name").uniqBy('id').compact().value();
        }

        filterItems(items, key) {
            return _.chain(items).filter((item) => {
                let keyStr = _.chain(key).toLower().deburr().value();
                let title = _.chain(item).get("full_name").toLower().deburr().value();
                return _.includes(title, keyStr);
            })
        }

        clearSearch() {
            this.contacts = this.initContacts;
            this.physicians = this.initPhysicians;
            this.mdSelectEvent('close');
        }

        searchPatient(ev) {
            let nEqual = {"$ne": true};

            this.modalService.showModal('patient_modal', {
                is_archived: nEqual, is_deleted: nEqual, is_draft: nEqual
            }, 'referring_contact', ev);
        }

        patientCallBack(msg) {
            if (msg.subject === 'referring_contact.patient_modal' && msg.data.id !== this.patient) {
                const subReferringContactID = _.get(msg.data, 'referring_contact.id');
                const subReferringContactType = _.get(msg.data, 'referring_contact._model');

                if (subReferringContactID === this.patient && subReferringContactType === 'Patient') return this.toast.show(this.simpleToast);

                this.patientService.getMinimalPatient(msg.data.id)
                    .then(data => {
                        this.cancel();

                        let patient = _.assign(data, {
                            track_by: 'patient', '_module': 'patient.models', '_model': 'Patient'
                        });

                        setTimeout(() => this.resetPatient(patient));
                    });
            }

            else if (msg.data.id === this.patient) {
                this.toast.show(this.simpleToast);
            }
        }

        resetPatient(patient) {
            this.patients = _.castArray(patient);
            this.internalValue = patient;
            this.internalValueChanged();
        }

        showModal($event) {
            this.dialog.show(_.assign({}, CONTACT_DIALOG, {
                targetEvent: $event,
                locals: {
                    hasBooth: true,
                    isPhysician: true
                }
            })).then(data => {
                this.cancel();

                let items = data['_model'] === 'ReferringContact' ? this.initContacts : this.initPhysicians;

                items = _.chain(items)
                    .pushOrUpdate(data)
                    .sortBy('full_name')
                    .value();

                this.filter("");
                this.internalValue = data;
                this.internalValueChanged();
            });
        }


    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, $attrs) {
        const isRequired = $elem.is("[required]") ? "required" : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";
        const ngDisabled = $elem.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-input-container>
                <label translate-once="${$attrs['mnLabel']}"></label>
                <md-select md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')" ng-model="vm.internalValue"
                    ng-model-options="{ trackBy: '$value.track_by'}" ng-change="vm.internalValueChanged()" 
                    ${isRequired} ${isDisabled} ${ngRequired} ${ngDisabled}>
                    <mn-select-search-header subject="vm.eventSubject" search-func="vm.filter($event)"></mn-select-search-header>
                    <md-optgroup label="{{ 'referring_contact' | translate }}" ng-if="vm.contacts.length > 0">
                        <md-option ng-repeat="item in vm.contacts track by item.track_by" ng-value="item" ng-bind="::item.full_name"></md-option>
                    </md-optgroup>
                    <md-optgroup label="{{ 'physician_contact' | translate }}" ng-if="vm.physicians.length > 0">
                        <md-option ng-repeat="item in vm.physicians track by item.track_by" ng-value="item" ng-bind="::item.full_name"></md-option>
                    </md-optgroup>
                    <md-optgroup label="{{ 'patients' | translate }}" ng-if="vm.patients.length > 0">
                        <md-option ng-repeat="item in vm.patients track by item.track_by" ng-value="item" ng-bind="::item.full_name"></md-option>
                    </md-optgroup>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${isDisabled} ${ngDisabled} class="md-icon-button" ng-click="vm.showModal($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ${ngDisabled} class="md-icon-button" ng-click="vm.searchPatient($event)" aria-label="find model" tabindex="-1">
                        <md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ${ngDisabled} ng-if="vm.value" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            patient: '<',
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: ReferringContactCtrl,
        require: {ngModelController: "ngModel"}
    }

})();
