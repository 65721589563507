/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {

    'use strict';

    module.exports = organizationService;

    organizationService.$inject = ['$q', '$http'];

    function organizationService($q, $http) {
        let self = this;

        self.getOrganizations = getOrganizations;
        self.saveOrganization = saveOrganization;
        self.removeOrganization = removeOrganization;

        function getOrganizations() {
            let deferred = $q.defer();
            let url = "/api/organization/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function saveOrganization(item) {
            let deferred = $q.defer();
            let url = "/api/organization/";

            if (!_.isNil(item.id)) url += item.id + "/";

            $http.post(url, item)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function removeOrganization(organization) {
            let url = "/api/organization/" + organization.id + '/';
            return $http.delete(url);
        }

    }

})();