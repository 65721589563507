(function () {

    'use strict';

    class ProcedureFormDialogCtrl {
        constructor($q, $http, $mdDialog) {
            this.q = $q;
            this.http = $http;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$q","$http","$mdDialog"];
        }

        $onInit() {
            this.model = this.model ? this.model : {};
            this.url = this.url ? this.url : '/api/procedure/';
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            const url = `${this.url}${this.model.id ? this.model.id + '/' : ''}`;
            this.promise = this.http.post(url, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ProcedureFormDialogCtrl,
        template: require("./procedure-form-dialog.tpl.html"),
    };

})();
