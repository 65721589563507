(function () {

    'use strict';

    const MAX_WRITE_SIZE = 20;
    const Table_CRC8 = [0x00, 0x07, 0x0E, 0x09, 0x1C,
        0x1B, 0x12, 0x15, 0x38, 0x3F, 0x36, 0x31, 0x24, 0x23, 0x2A, 0x2D,
        0x70, 0x77, 0x7E, 0x79, 0x6C, 0x6B, 0x62, 0x65, 0x48, 0x4F, 0x46,
        0x41, 0x54, 0x53, 0x5A, 0x5D, 0xE0, 0xE7, 0xEE, 0xE9, 0xFC, 0xFB,
        0xF2, 0xF5, 0xD8, 0xDF, 0xD6, 0xD1, 0xC4, 0xC3, 0xCA, 0xCD, 0x90,
        0x97, 0x9E, 0x99, 0x8C, 0x8B, 0x82, 0x85, 0xA8, 0xAF, 0xA6, 0xA1,
        0xB4, 0xB3, 0xBA, 0xBD, 0xC7, 0xC0, 0xC9, 0xCE, 0xDB, 0xDC, 0xD5,
        0xD2, 0xFF, 0xF8, 0xF1, 0xF6, 0xE3, 0xE4, 0xED, 0xEA, 0xB7, 0xB0,
        0xB9, 0xBE, 0xAB, 0xAC, 0xA5, 0xA2, 0x8F, 0x88, 0x81, 0x86, 0x93,
        0x94, 0x9D, 0x9A, 0x27, 0x20, 0x29, 0x2E, 0x3B, 0x3C, 0x35, 0x32,
        0x1F, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0D, 0x0A, 0x57, 0x50, 0x59,
        0x5E, 0x4B, 0x4C, 0x45, 0x42, 0x6F, 0x68, 0x61, 0x66, 0x73, 0x74,
        0x7D, 0x7A, 0x89, 0x8E, 0x87, 0x80, 0x95, 0x92, 0x9B, 0x9C, 0xB1,
        0xB6, 0xBF, 0xB8, 0xAD, 0xAA, 0xA3, 0xA4, 0xF9, 0xFE, 0xF7, 0xF0,
        0xE5, 0xE2, 0xEB, 0xEC, 0xC1, 0xC6, 0xCF, 0xC8, 0xDD, 0xDA, 0xD3,
        0xD4, 0x69, 0x6E, 0x67, 0x60, 0x75, 0x72, 0x7B, 0x7C, 0x51, 0x56,
        0x5F, 0x58, 0x4D, 0x4A, 0x43, 0x44, 0x19, 0x1E, 0x17, 0x10, 0x05,
        0x02, 0x0B, 0x0C, 0x21, 0x26, 0x2F, 0x28, 0x3D, 0x3A, 0x33, 0x34,
        0x4E, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5C, 0x5B, 0x76, 0x71, 0x78,
        0x7F, 0x6A, 0x6D, 0x64, 0x63, 0x3E, 0x39, 0x30, 0x37, 0x22, 0x25,
        0x2C, 0x2B, 0x06, 0x01, 0x08, 0x0F, 0x1A, 0x1D, 0x14, 0x13, 0xAE,
        0xA9, 0xA0, 0xA7, 0xB2, 0xB5, 0xBC, 0xBB, 0x96, 0x91, 0x98, 0x9F,
        0x8A, 0x8D, 0x84, 0x83, 0xDE, 0xD9, 0xD0, 0xD7, 0xC2, 0xC5, 0xCC,
        0xCB, 0xE6, 0xE1, 0xE8, 0xEF, 0xFA, 0xFD, 0xF4, 0xF3];

    module.exports = oximetryService;

    oximetryService.$inject = ["$http", "$q", "mnWebSocket", "system", "$rootScope"];

    function oximetryService($http, $q, mnWebSocket, system, $rootScope) {
        var self = this;
        self.saveExam = saveExam;
        self.getExams = getExams;
        self.getDateUint8Array = getDateUint8Array;
        self.readSFLOAT = readSFLOAT;
        self.newDateFromArray = newDateFromArray;
        self.toInt16 = toInt16;
        self.ByteArrayToInt32 = ByteArrayToInt32;
        self.callO2RingCharacteristic = callO2RingCharacteristic;
        self.calCRC8 = calCRC8;

        var fullDateTimeFormat = system['full_datetime_format'].js;


        function saveExam(_exam) {
            var deferred = $q.defer();
            var url = `/api/oximetry-exam/${!_.isNil(_exam.id) ? _exam.id + "/" : ""}`;
            $http.post(url, _exam)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getExams(_id) {
            return mnWebSocket.call('oximetry.OximetryExam.get_exams', {_pk: _id});
        }


        //******* utils**********
        function toInt16(num_arr) {
            // number in their orders must be giving
            return parseInt(("00000000" + num_arr[1].toString(2)).slice(-8).concat(("00000000" + num_arr[0].toString(2)).slice(-8)), 2);
        }

        function getDateUint8Array(d) {
            return _.concat(toInt16Array(d.getFullYear()),
                [d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), 0, 0, 0])
        }

        function toInt16Array(num1) { //2018 =>[226,7]
            return _.chain((("00000000" + num1.toString(2)).replace(/^0+/, '')).match(/.{1,8}(?=(.{8})+(?!.))|.{1,8}$/g)).reverse().map(function (e) {
                return parseInt(e, 2)
            }).value()
        }

        function readSFLOAT(dv, offset = 0, littleEndian = false) {
            // if reserving the same order of your data littleIndian = true as i give 2 unit8  offset=0
            // ex :dv=new DataView( Uint8Array.of(75,0).buffer || new Uint8Array([75,0]))
            var data = dv.getUint16(offset, littleEndian);
            var mantissa = (data & 0x0FFF);
            var exponent = data >> 12;
            if ((mantissa & 0x0800) > 0) {
                mantissa = -1 * (~(mantissa - 0x01) & 0x0FFF)
            }
            if (exponent >= 0x0008) {
                exponent = -((0x000F + 1) - exponent);
            }
            return mantissa * Math.pow(10, exponent);
        }

        function newDateFromArray(args, scope) {
            // args  ex [2018,7,16,15,56,59]
            return new (Function.prototype.bind.apply(Date, [scope].concat(args)));
        }

        function ByteArrayToInt32(arr) {
            // from [66,0] into 66
            let ret = 0;
            if (arr[7] >= 128) { // negative number
                arr.forEach((val, i) => {
                    ret += (255 - val) * 256 ** i
                });
                ret = ret * -1 - 1;
            } else arr.forEach((val, i) => {
                ret += val * 256 ** i;
            });
            return ret
        }

        function callO2RingCharacteristic(writeCharacteristic, bluetoothServer, bluetoothDevice, oxiServices, cmd) {

            if (writeCharacteristic && bluetoothServer.gatt.connected) {
                writeCharacteristic.writeValue(cmd);
            } else {
                bluetoothDevice.getPrimaryService(_.get(oxiServices, 'OxiService.code'))
                    .then(service => {
                        return service.getCharacteristic(_.get(oxiServices, 'OxiService.writeCharacteristic'))
                    })
                    .then(write_c => {
                        writeCharacteristic = write_c;
                        if (cmd.length <= 20) {
                            writeCharacteristic.writeValue(cmd);
                        } else {
                            let length = MAX_WRITE_SIZE;
                            chunkWrite(cmd, writeCharacteristic, 0, MAX_WRITE_SIZE);

                        }
                    })
                    .catch(err => console.log('error:', err));
            }
        }



        function chunkWrite(cmd, writeCharacteristic,i ,length) {
                writeCharacteristic.writeValue(new Uint8Array(_.slice(cmd, i, length))).then(
                    (data) => {
                        while (i < cmd.length) {
                        i += 20;
                        length +=
                            cmd.length - length >= 20 ? i : cmd.length - length;
                        chunkWrite(cmd, writeCharacteristic, i, length)
                    } }
                );
        }

        function calCRC8(buf) {
            if ((buf == null) || (buf.length == 0)) {
                return 0;
            }
            let crc = 0;
            for (let i = 0; i < buf.length - 1; i++) {
                crc = Table_CRC8[0x00ff & (crc ^ buf[i])];
            }

            return crc;
        }

    }
})();