/**
 * Created by BETALOS on 24/02/2017.
 */
(function () {

    'use strict';

    module.exports = mnBiologyModelRow;

    mnBiologyModelRow.$inject = ["$compile"];

    function mnBiologyModelRow($compile) {

        var directive = {
            restrict: 'A',
            scope: {
                item: "=mnBiologyModelRow",
                onDelete: "&",
            },
            link: link
        };

        function link($scope, $element, $attrs) {
            $scope.$watch('item', handleWatch);

            let childScope = null;

            let $removeButton = $([
                '<md-button class="md-icon-button" aria-label="delete">',
                    '<md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
                '</md-button>'
            ].join(''));


            function handleWatch(item) {
                if (_.isNil(item)) return;

                if (item['is_unique']) handleUnique();
                else {
                    watchDetails(item);
                    $scope.$watchCollection("item.value", valueChanged);
                }

                if(childScope) childScope.$destroy();

                childScope = $scope.$new();
                let compiledDirective = $compile($element.contents());
                let directiveElement = compiledDirective(childScope);

                $element.html(directiveElement);
            }

            function handleUnique() {
                var tr = require('parameters/views/biology-details-unique.tpl.html');

                tr = _.replace(tr, /vm.biologyExam/g, "item.exam");

                var $tr = $(tr);

                var $button = $removeButton.clone().attr("ng-click", "onDelete({item: item})");

                $('td:last', $tr).html($button);

                $element.html($tr);
            }

            function watchDetails(item) {
                var $headerTr = $(require('parameters/views/biology-details-not-unique-header.tpl.html'));
                var tr = require('parameters/views/biology-details-not-unique.tpl.html');

                tr = _.replace(tr, /vm.biologyExam.details/g, "elements");
                tr = _.replace(tr, /vm.biologyExam/g, "item");

                var $tr = $(tr).addClass('sub-detail');

                var $button = $removeButton.clone().attr("ng-click", "onDelete({item: item, uid: elem.uid})");

                $('td:last', $tr).html($button);

                $element
                    .html($headerTr)
                    .append($tr);
            }

            function valueChanged() {
                $scope.elements = _.filter($scope.item.exam.details, function (elem) {
                    return _.includes(_.keys($scope.item.value), elem.uid);
                })
            }

        }

        return directive;
    }

})()