/**
 * Created by BETALOS on 13/01/2017.
 */
(function () {

    'use strict';

    const GXP_DIALOG = require("specifics/dialogs/gxp-setup-dialog");

    module.exports = MeasuresSetupCtrl;

    MeasuresSetupCtrl.$inject = ["measureService", "$mdDialog", "$mdToast", "$timeout", "$translate", "dragulaService", "$scope", "configService", "$q"];

    function MeasuresSetupCtrl(measureService, $mdDialog, $mdToast, $timeout, $translate, dragulaService, $scope, configService, $q) {
        let vm = this;

        let dialog = {
            controller: DialogController,
            controllerAs: "vm",
            template: require("parameters/views/measures-dialog.tpl.html"),
            parent: $(document.body),
            locals: {},
            clickOutsideToClose: true,
            bindToController: true
        };

        let bag = "item-bag";

        vm.$onInit = init;

        vm.handleMeasure = handleMeasure;
        vm.handleDisabled = handleDisabled;
        vm.configGXP = configGXP;
        vm.handleConfig = _.mnDelay(handleConfig, 400);

        function init() {
            vm.measures = [];

            vm.promise = $q.all([measureService.getMeasures(false), configService.get("patient_measure_config")])
                .then(success);

            function success(data) {
                vm.measures = data[0];
                vm.config = data[1] || {};
                vm.hasGrowthXP = _.get(configService.activeModules, 'has_growthxp');
            }

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let drake = dragulaService.find($scope, bag).drake;

            drake.on("dragend", function () {
                _.mnDelay(endSort, 500)();
            });
        }

        function handleMeasure(measure, ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {measure: _.cloneDeep(measure)}
            })).then(done);

            function done(data) {
                vm.measures = _.pushOrUpdate(vm.measures, data);
            }
        }


        function handleDisabled(measure) {
            measureService.handleMeasure(measure)
                .then(_.mnDelay(success, 400));

        }

        function endSort() {
            configService.set({"measure_config": getSortConfig()}, true)
                .then(success);
        }

        function handleConfig() {
            configService.set({"patient_measure_config": vm.config})
                .then(_.mnDelay(success, 400));
        }

        function success() {
            let simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $timeout(function () {
                $mdToast.show(simpleToast);
            }, 100);
        }

        function getSortConfig() {
            return _.reduce(vm.measures, function (config, item, key) {
                return _.set(config, item.id.toString(), key);
            }, {})
        }

        function configGXP(event) {
            $mdDialog.show(_.assign({}, GXP_DIALOG, {
                targetEvent: event
            }));
        }
    }

    DialogController.$inject = ["$mdDialog", "measureService", "authService"];

    function DialogController($mdDialog, measureService, authService) {
        let vm = this;

        vm.measureTypes = ['integer', 'float', 'string', 'boolean', 'date', 'calculated'];
        vm.require = ['integer', 'float', 'calculated'];

        vm.isRoot = authService.isRootProfile();

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            measureService.handleMeasure(vm.measure)
                .then($mdDialog.hide);
        }
    }

})();
