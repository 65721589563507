/**
 * Created by amine on 31/08/2017.
 */


(function () {

    'use strict';

    let mnMeasurements = {
        controller: mnMeasurementsCtrl,
        controllerAs: 'vm',
        bindings: {
            patient: '=',
            measurement: "<?",
            readonly: "<?"
        },
        restrict: 'E',
        template: require("patient/views/measurement.tpl.html"),
    };

    mnMeasurementsCtrl.$inject = ["measureService", "$mdDialog", "$scope"];

    function mnMeasurementsCtrl(measureService, $mdDialog, $scope) {
        let vm = this;
        let dialog = require('../dialogs/measurement-dialog');
        let subscription = null;

        vm.$onInit = init;
        vm.$onDestroy = onDestroy;
        vm.editMeasurement = editMeasurement;
        vm.removeMeasurement = removeMeasurement;

        function init() {
            subscription = measureService['measurementsComponent'].subscribe(addMeasurement);

            $scope.$watch("vm.patient", patientLoaded);
        }

        function onDestroy() {
            if (!_.isNil(subscription)) subscription.unsubscribe();
        }

        function patientLoaded(value) {
            if (_.isNil(value)) return false;
            if (!vm.measures && !vm.measurements) {
                vm.promise = measureService['getMeasuresAndMeasurement'](vm.patient).then(success);
            }

            function success(data) {
                vm.measures = _.reduce(data.measures, function (result, value) {
                    value['label'] = _.isNull(value.unit) ? value.name : _.format('{0} ({1})', value.name, value.unit.value);
                    return _.concat(result, value);
                }, []);

                vm.measurements = data.measurements;
            }
        }


        function addMeasurement(ev) {
            if (!(ev instanceof jQuery.Event)) return false;
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    measures: vm.measures,
                    patient: vm.patient,
                }
            })).then(editSuccess, _.noop);
        }

        function editMeasurement(measurement, ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    measures: vm.measures,
                    measurement: measurement,
                    patient: vm.patient
                }
            })).then(editSuccess);
        }

        function editSuccess(data) {
            vm.measurements = _.chain(vm.measurements)
                .pushOrUpdate(data)
                .sortBy(function (item) {
                    return -1 * item['sort_date'];
                }).value();
        }

        function removeMeasurement(measurement) {
            vm.promise = measureService.removeMeasurement(measurement.id, vm.patient)
                .then(success);

            function success() {
                let index = _.findIndex(vm.measurements, _.pick(measurement, 'id'));
                vm.measurements.splice(index, 1);
            }
        }
    }

    module.exports = mnMeasurements;


})();
