(function () {

    'use strict';

    const COLOR_PICKER_PARAMS = require('../json/color-picker-config.json');

    class SurveySetupFormCtrl {
        constructor($translate, $transition$, blockService, $state, $mdToast) {
            this.state = $state;
            this.toast = $mdToast;
            this.transition$ = $transition$;
            this.blockService = blockService;

            this.options = _.assign(COLOR_PICKER_PARAMS, {label: $translate['instant']('color')});
            this.simpleToast = this.toast.simple()
                .hideDelay(1500)
                .position("bottom left")
                .textContent($translate['instant']('exam_setup_edit_success'));
        }

        static get $inject() {
            return ["$translate", "$transition$", "blockService", "$state", "$mdToast"];
        }

        $onInit() {
            let currentBlock = _.get(this.transition$.params('to'), 'block');

            if (currentBlock) this.promise = this.blockService.getBlock(currentBlock, 'survey')
                .then(data => this.block = data);
            else this.block = {questions: [], meanings: []};
        }

        goBack() {
            this.state.go('app.parameters.survey-setup');
        }

        handleSurvey() {
            this.promise = this.blockService.handleBlock(this.block, 'survey')
                .then(data => {
                    this.block = data;
                    this.toast.show(this.simpleToast);
                    this.state.go("app.parameters.survey-setup-form", {block: data.id});
                });
        }

        addQuestion() {
            this.block.questions.push({answers: []});
        }

        addAnswer(question) {
            question.answers.push({});
        }

        removeQuestion(index) {
            this.block.questions.splice(index, 1);
        }

        removeAnswer(question, index) {
            question.answers.splice(index, 1)
        }

        addMeaning() {
            this.block.meanings.push({});
        }

        removeMeaning(index) {
            this.block.meanings.splice(index, 1);
        }

    }

    module.exports = SurveySetupFormCtrl;

})();