/**
 * Created by BETALOS on 04/07/2017.
 */
(function () {

    'use strict';

    const PROFILE_DIALOG = require('auth/dialogs/profile-dialog');

    class ProfileSetupCtrl {
        constructor(authService, $mdDialog) {
            this.dialog = $mdDialog;
            this.service = authService;

            this.currentProfile = this.service.profile;
        }

        static get $inject() {
            return ["authService", "$mdDialog"];
        }

        $onInit() {
            this.promise = this.service.getProfiles()
                .then(data => this.profiles = _.orderBy(data, p => [p.is_root ? 0 : 1, _.lowerCase(p.name)], ['asc', 'asc']));
        }

        handleProfile(profile, ev, copy) {
            this.dialog.show(_.assign({}, PROFILE_DIALOG, {
                targetEvent: ev,
                locals: {profile: _.cloneDeep(profile), copy}
            })).then(data => _.pushOrUpdate(this.profiles, data));
        }

    }


    module.exports = ProfileSetupCtrl;

})();
