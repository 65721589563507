(function () {

    'use strict';

    class DeviceDriversFormDialogCtrl {
        constructor($mdDialog, interfacingService) {
            this.dialog = $mdDialog;
            this.interfacingService = interfacingService;
        }

        static get $inject() {
            return ["$mdDialog", "interfacingService"];
        }

        $onInit() {
            if (this.driver) this.defaultDriver = {id: this.driver['default_config_id']};
            else this.driver = {};
        }

        cancel() {
            this.dialog.cancel();
        }

        selectDriver() {
            if (this.defaultDriver['id'] !== this.driver['default_config_id']) this.driver = this.defaultDriver;
        }

        submit() {
            this.interfacingService.handleDeviceConfig(this.device, this.driver)
                .then(data => this.dialog.cancel(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: DeviceDriversFormDialogCtrl,
        template: require("./device-driver-form-dialog.tpl.html"),
    };

})();
