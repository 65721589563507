/**
 * Created by Amine on 18/10/2021.
 */
(function () {

    'use strict';

    class ServerPanelCtrl {
        constructor(maintenanceService) {
            this.maintenanceService = maintenanceService;
        }

        static get $inject() {
            return ["maintenanceService"];
        }

        $onInit() {

        }

        reboot() {
            this.maintenanceService.serverShutdown("reboot")
        }

        shutdown() {
            this.maintenanceService.serverShutdown("shutdown")
        }

    }

    module.exports = ServerPanelCtrl;

})();
