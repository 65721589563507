(function () {

    'use strict';

    class ConsentModelFormDialogCtrl {
        constructor($scope, $mdDialog, externalConnectionService) {
            this.scope = $scope;
            this.dialog = $mdDialog;
            this.externalConnectionService = externalConnectionService;
        }

        static get $inject() {
            return ["$scope", "$mdDialog", "externalConnectionService"];
        }

        cancel() {
            this.dialog.cancel();
        }

        handleFiles(files) {
            this.scope.$applyAsync(() => this.item.content = files[0]);
        }

        submit() {
            this.externalConnectionService.saveConsentModel(this.item)
                .then((data) => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ConsentModelFormDialogCtrl,
        template: require("./consent-model-form-dialog.tpl.html"),
    };

})();
