/**
 * Created by BETALOS on 23/01/2017.
 */
(function () {

    'use strict';

    module.exports = CertificatesCategorySetupCtrl;

    CertificatesCategorySetupCtrl.$inject = ["visitService", "$mdDialog", "$translate", "certificateService", "$mdToast"];

    function CertificatesCategorySetupCtrl(visitService, $mdDialog, $translate, certificateService, $mdToast) {
        let vm = this;

        let dialog = {
            controller: CategoryFormDialogController,
            controllerAs: "vm",
            template: require("certificate/views/category.tpl.html"),
            parent: $(document.body),
            clickOutsideToClose: true,
            bindToController: true
        };

        vm.$onInit = init;
        vm.handleCategory = handleCategory;
        vm.removeCategory = removeCategory;

        function init() {
            vm.promise = certificateService.getCategories()
                .then(success);

            function success(data) {
                vm.categories = data;
            }

        }

        /*function success() {
            let simpleToast = $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $mdToast.show(simpleToast);
        }*/

        function handleCategory(category, ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(category)
                }
            })).then(done);

            function done(data) {
                vm.categories = _.pushOrUpdate(vm.categories, data);
            }
        }


        function removeCategory(category, ev) {
            const confirm = $mdDialog.confirm()
                .title($translate['instant']('certificate_remove_confirm', {title: category.name}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = certificateService.removeCategory(category.id)
                    .then(done)
            }

            function done() {
                _.pull(vm.categories, category);
            }
        }
    }

    CategoryFormDialogController.$inject = ["$mdDialog", "certificateService"];

    function CategoryFormDialogController($mdDialog, certificateService) {
        let vm = this;

        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function submit() {
            certificateService.saveCategory(vm.model)
                .then($mdDialog.hide)
        }
    }

})();
