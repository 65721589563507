/**
 * Created by BETALOS on 04/01/2017.
 */
(function () {

    'use strict';


    class BlockSetupCtrl {
        constructor(blockService, $state) {
            this.state = $state;
            this.blockService = blockService;

            this.htmlBlocks = [];
        }

        static get $inject() {
            return ["blockService", "$state"];
        }

        $onInit() {
            this.promise = this.blockService.getBlockList('html')
                .then(data => this.htmlBlocks = data);
        }

        handleBlock(block) {
            this.state.go("app.parameters.block-setup-form", {
                block: block ? block.id : null
            });
        }
    }

    module.exports = BlockSetupCtrl;

})();