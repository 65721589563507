/**
 * Created by BETALOS on 21/02/2017.
 */
(function () {

    'use strict';

    module.exports = BiologySetupCtrl;

    const {Subject} = require("rxjs");
    const BIOLOGY_PARAMETER_DIALOG = require('../dialogs/biology-parameter-dialog');

    BiologySetupCtrl.$inject = ["biologyService", "$q", "$mdDialog", "prescriptionService", "$translate"];

    function BiologySetupCtrl(biologyService, $q, $mdDialog, prescriptionService, $translate) {
        let vm = this;
        let initBiologyExams = [];

        vm.$onInit = init;

        vm.addBiologyExam = addBiologyExam;
        vm.addBiologySubExam = addBiologySubExam;
        vm.editBiologyExam = editBiologyExam;
        vm.chooseBiology = chooseBiology;
        vm.deleteDetail = deleteDetail;
        vm.removeExam = removeExam;

        vm.subscriber = new Subject();

        vm.editBiologyTitle = _.mnDelay(editBiologyTitle, 750);

        vm.editBiologyModel = editBiologyModel;
        vm.copyBiologyModel = copyBiologyModel;
        vm.addBiologyModel = addBiologyModel;

        vm.submitModel = submitModel;
        vm.deleteModel = deleteItem;

        function init() {
            let promises = [biologyService.getBiologyExams(), prescriptionService.getModelBiologyPrescriptionList()];

            vm.lock = true;

            vm.subscriber.subscribe(
                selectExam
            )

            vm.promise = $q.all(promises)
                .then(success);

            function success(data) {
                initBiologyExams = _.head(data);

                vm.biologyExams = initBiologyExams;
                vm.currentExam = _.head(vm.biologyExams);

                vm.biologyExamsModels = data[1];

                vm.prescriptionModel = _.chain(vm.biologyExamsModels).head().cloneDeep().value();

                vm.prescriptionModel = vm.prescriptionModel ? vm.prescriptionModel : {
                    details: [],
                    prescription_type: 'BiologyPrescription'
                };
            }
        }

        function addBiologyExam(ev) {
            $mdDialog.show(_.assign({}, BIOLOGY_PARAMETER_DIALOG, {
                targetEvent: ev,
                locals: {
                    isParameter: true
                }
            })).then(success);
        }

        function addBiologySubExam(item, ev) {
            $mdDialog.show(_.assign({}, BIOLOGY_PARAMETER_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid: null, isParameter: false,
                    biologyExam: _.cloneDeep(item)
                }
            })).then(success);
        }

        function editBiologyExam(item, uid, ev) {
            $mdDialog.show(_.assign({}, BIOLOGY_PARAMETER_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid,
                    isParameter: _.isUndefined(uid),
                    biologyExam: _.cloneDeep(item)
                }
            })).then(success);
        }

        function editBiologyTitle() {
            biologyService.editBiologyExamTitle(vm.currentExam.id, _.pick(vm.currentExam, 'title'));
        }

        function success(data) {
            let index = _.findIndex(vm.biologyExams, _.pick(data, 'id'));

            if (index === -1) initBiologyExams.push(data);
            else initBiologyExams.splice(index, 1, data);

            chooseBiology(data);
        }

        function chooseBiology(biology) {
            vm.currentExam = biology;
        }


        function deleteDetail(item, uid) {
            _.remove(item.details, {uid: uid});

            biologyService.handleBiologyExam(item)
                .then(success);
        }

        function removeExam(item, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('biology_setup_confirm', _.pick(item, 'title')))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('biology_setup_ok'))
                .cancel($translate['instant']('biology_setup_cancel'));

            $mdDialog.show(confirm).then(success);

            function success() {
                biologyService.removeBiologyExam(item)
                    .then(removeSuccess)
            }

            function removeSuccess() {
                _.remove(initBiologyExams, _.pick(item, 'id'));
                if (vm.currentExam.id == item.id) vm.currentExam = _.head(vm.biologyExams);
            }
        }

        function selectExam(item) {
            if (_.size(vm.prescriptionModel.details) == 0 && _.isEmpty(vm.prescriptionModel.title)) {
                vm.prescriptionModel.title = _.get(item, 'exam.title', '');
            }

            let exam = _.find(vm.prescriptionModel.details, {
                exam: _.pick(item.exam, 'id')
            });

            if (_.isUndefined(exam)) vm.prescriptionModel.details.push(item);
            else _.assign(exam.value, item.value);
        }

        function editBiologyModel(model) {
            vm.prescriptionModel = _.cloneDeep(model);
        }

        function copyBiologyModel(model) {
            vm.prescriptionModel = _.pick(model, ['details', 'prescription_type']);
        }

        function addBiologyModel() {
            vm.prescriptionModel = {
                details: [],
                prescription_type: 'BiologyPrescription'
            };

            vm.showBiologyExamTree = true;
        }

        function submitModel() {
            prescriptionService.saveBiologyPrescription(vm.prescriptionModel)
                .then(modelSuccess);

            function modelSuccess(data) {
                vm.prescriptionModel.id = data.id;
                vm.biologyExamsModels = _.pushOrUpdate(vm.biologyExamsModels, data);
            }
        }

        function deleteItem(item) {
            prescriptionService.deleteItem(item)
                .then(handleDelete);

            function handleDelete() {
                _.remove(vm.biologyExamsModels, _.pick(item, 'id'));

                if (_.eq(item.id, vm.prescriptionModel.id)) vm.prescriptionModel = _.chain(vm.biologyExamsModels).head().cloneDeep().value();
            }
        }


    }

})();
