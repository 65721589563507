(function () {

    'use strict';

    class DialogCtrl {
        constructor($mdDialog, maintenanceService) {
            this.dialog = $mdDialog;
            this.maintenanceService = maintenanceService;
        }

        static get $inject() {
            return ["$mdDialog", "maintenanceService"];
        }

        $onInit() {
            this.config = this.config ? this.config : {};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.maintenanceService.handleTabletClient(this.config)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: DialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/patient-tablet-dialog.tpl.html"),
    };

})();
