(function () {

    'use strict';

    class CloudPlatformDialogCtrl {
        constructor($mdDialog, CloudPlatformsService) {
            this.dialog = $mdDialog;
            this.CloudPlatformsService = CloudPlatformsService;
        }

        static get $inject() {
            return ["$mdDialog", "CloudPlatformsService"];
        }

        $onInit() {
            this.platform = this.platform ?? {};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.CloudPlatformsService.savePlatform(this.platform)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: CloudPlatformDialogCtrl,
        template: require("../views/cloud-platform-dialog.tpl.html"),
    };

})();
