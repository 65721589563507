(function () {

    'use strict';


    class SurveySetupCtrl {
        constructor($state, blockService) {
            this.state = $state;
            this.blockService = blockService;
        }

        static get $inject() {
            return ["$state", "blockService"];
        }

        $onInit() {
            this.promise = this.blockService.getBlockList('survey')
                .then(data => this.surveyBlocks = data);
        }

        handleSurvey(block) {
            this.state.go("app.parameters.survey-setup-form", {
                block: block ? block.id : null
            });
        }
    }


    module.exports = SurveySetupCtrl;

})();