/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {

    'use strict';

    class SpecialityDialogCtrl {
        constructor($mdDialog, actorService) {
            this.dialog = $mdDialog;
            this.service = actorService;

            this.speciality = this.speciality || null;
        }

        static get $inject() {
            return ["$mdDialog", "actorService"];
        }

        $onInit() {
            this.model = this.speciality ? this.speciality : {};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.service.handleSpeciality(this.model)
                .then(data => this.dialog.hide(data));
        }
    }

    const SPECIALITY_DIALOG = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: SpecialityDialogCtrl,
        template: require("../views/speciality-form.tpl.html"),
    };

    class SpecialitiesCtrl {
        constructor($mdDialog, actorService) {
            this.dialog = $mdDialog;
            this.service = actorService;
        }

        static get $inject() {
            return ["$mdDialog", "actorService"];
        }

        $onInit() {
            this.promise = this.service.getSpecialities()
                .then(data => this.specialities = _.orderBy(data, ['short_title', 'long_title']));
        }

        handleSpeciality(speciality, ev) {
            const dialog = _.assign({}, SPECIALITY_DIALOG, {
                targetEvent: ev,
                locals: {speciality: _.cloneDeep(speciality)},
            });

            this.dialog.show(dialog).then(data => {
                this.specialities = _.chain(this.specialities).pushOrUpdate(data)
                    .orderBy(['short_title', 'long_title']).value();
            });
        }

    }

    module.exports = SpecialitiesCtrl;

})();