(function () {

    'use strict';

    var angular = require('angular');

    // var NoninContainerCtrl = require("./controllers/NoninContainerCtrl");

    var mnOximetry = require('./components/oximetry');
    var mnOximetryChart = require('./components/oximetry-chart');
    var mnOximetryBody = require('./components/oximetry-body');
    var mnOximetryConfigTable = require('./components/oximetry-config-table');
    var oximetryService = require('./services/oximetryService');


    angular
        .module("medinet")
        .component("mnOximetry", mnOximetry)
        .component("mnOximetryChart", mnOximetryChart)
        .component("mnOximetryBody", mnOximetryBody)
        .component("mnOximetryConfigTable", mnOximetryConfigTable)
        .service("oximetryService", oximetryService)
})();