(function () {

    'use strict';

    class AddSequenceDialogCtrl {
        constructor($mdDialog, sequenceService, $mdToast, $translate) {
            this.dialog = $mdDialog;
            this.sequenceService = sequenceService;
            this.$mdToast = $mdToast;
            this.$translate = $translate;

            this.sequence = {start_by: 1, filling: 1, increment_by: 1}
        }

        static get $inject() {
            return ["$mdDialog", "sequenceService", '$mdToast', '$translate'];
        }

        $onInit() {
            this.updateNextValue();
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.sequence = _.assign(this.sequence, _.pick(_.find(this.models, {model_name: this.sequence.model_name}), 'package', 'label'));
            this.sequenceService.checkDependencies(this.sequence).then(success.bind(this));

            function success(data) {
                if (!data) this.promise = this.sequenceService.saveSequence(this.sequence).then(function (data) {
                    this.$mdToast.show(this.$mdToast.simple()
                        .textContent(this.$translate.instant('sequence_add_success'))
                        .position("bottom left")
                        .hideDelay(1500));
                }.bind(this), this.showWarning.bind(this));
                else this.showWarning();

            }

            this.dialog.hide(this.sequence);
        }

        showWarning() {
            this.dialog.show(
                this.dialog.alert()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .multiple(true)
                    .title('')
                    .textContent(this.$translate.instant('sequence_operation_add_warn'))
                    .ariaLabel('confirm')
                    .ok(this.$translate.instant('ok'))
            );
        }

        updateNextValue() {
            this.sequence.next_value = this.fillNumber(this.sequence['start_by'], this.sequence['filling']);
        }

        fillNumber(n, places) {
            let zero = places - n.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + n;
        }

    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AddSequenceDialogCtrl,
        template: require("parameters/views/sequence.tpl.html"),
        focusOnOpen: false
    };

})();
