/**
 * Created by BETALOS on 23/11/2016.
 */
(function () {

    'use strict';

    class DevicesCtrl {
        constructor(interfacingService, $state) {
            this.state = $state;
            this.interfacingService = interfacingService;
        }

        static get $inject() {
            return ["interfacingService", "$state"];
        }

        $onInit() {
            this.promise = this.interfacingService.getDevices()
                .then(data => this.devices = data);
        }

        handleDevice(device) {
            this.state.go('app.parameters.device-drivers', {device: device.id});
        }
    }

    module.exports = DevicesCtrl;

})();
