(function () {

    'use strict';

    class ExamTemplateDialogCtrl {
        constructor($mdDialog, examService) {
            this.dialog = $mdDialog;
            this.examService = examService;
        }

        static get $inject() {
            return ["$mdDialog", "examService"];
        }

        $onInit() {
            if (this.item) this.examService.getExamTemplate(this.item).then(data => this.item = data);
            else this.item = {};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.item = _.assign(this.item, {ignore: true});
            this.examService.saveExamTemplate(this.item)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ExamTemplateDialogCtrl,
        template: require("../views/exam-template-dialog.tpl.html"),
    };


})();