//edited by Amine 27/02/2020

(function () {

    'use strict';
    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');

    class PatientCtrl {
        constructor(
            frontDeskService, patientService, careSheetService, $mdToast, $translate, $mdDialog, visitService, $state,
            externalConnectionService, configService, billingService, medicalCareService, $scope
        ) {
            this.patientService = patientService;
            this.toast = $mdToast;
            this.translate = $translate;
            this.dialog = $mdDialog;
            this.externalConnectionService = externalConnectionService;
            this.configService = configService;
            this.scope = $scope;

            this.refresh = this.refresh || _.noop;
            this.promise = this.promise || null;

            this.archiveAction = {
                icon: 'mdi-package-down',
                label: 'patient_archive',
                resource: 'patient',
                action: 'get',
                behavior: 'remove',
                method: list => this.archive(list)
            };
            this.unarchiveAction = {
                icon: 'mdi-package-up',
                label: 'patient_archive_out',
                resource: 'patient',
                action: 'get',
                behavior: 'remove',
                method: list => this.unarchive(list)
            };
            this.deleteAction = {
                icon: 'mdi-delete',
                label: 'patient_delete',
                resource: 'patient',
                action: 'delete',
                behavior: 'remove',
                method: list => this.remove(list)
            };
            this.privatizeAction = {
                icon: 'mdi-eye-off',
                label: 'patient_hide',
                resource: 'patient',
                specialPower: true,
                action: 'get',
                behavior: 'remove',
                method: list => this.privatize(list)
            };
            this.unprivatizeAction = {
                icon: 'mdi-eye',
                label: 'patient_unhide',
                resource: 'patient',
                specialPower: true,
                action: 'get',
                behavior: 'remove',
                method: list => this.unprivatize(list)
            };
            this.restoreAction = {
                icon: 'mdi-delete-restore',
                label: 'patient_restore',
                resource: 'patient',
                action: 'delete',
                behavior: 'remove',
                method: list => this.restore(list)
            };
            this.sendSmsAction = {
                icon: 'mdi-email-check',
                label: 'send_sms',
                resource: 'sms',
                action: 'get',
                behavior: 'remove',
                method: (list, ev) => this.sendSms(list, ev)
            };

            visitService.visitSubLinks.subscribe(data => {
                let linkConfig = _.find(VISIT_SUB_LINKS, ['key', data.favorite]);

                this.actions = {
                    single: [
                        {
                            icon: 'mdi-pencil',
                            label: 'edition',
                            resource: 'patient',
                            action: 'update',
                            behavior: 'disable',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: item => patientService.goToEdition(item)
                        },
                        {
                            resource: 'visit',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            icon: linkConfig.icon,
                            label: linkConfig.tooltip,
                            method: (p, e) => frontDeskService.startShowVisitPatientFile(p, e, linkConfig.link)
                        },
                        {
                            icon: 'mdi-cash-multiple',
                            label: 'financial_status',
                            resource: 'visit',
                            action: 'get',
                            behavior: 'remove',
                            condition: "!item.is_deleted",
                            method: (p) => $state.go('app.patient-financial-statement', {id: p.id})
                        },
                        {
                            icon: 'mdi-calendar-plus',
                            label: 'new_rdv',
                            resource: 'appointment',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (p, e) => frontDeskService.addNewAppointment(p, e)
                        },
                        {
                            icon: 'mdi-calendar-text',
                            label: 'planning_module_label',
                            resource: 'appointment',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: p => patientService.goToPlanning(p)
                        },
                        {
                            icon: 'mdi-account-alert',
                            label: 'patient_alerts',
                            resource: 'notification',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (p, e) => patientService.patientAlerts(p, e)
                        },
                        {
                            icon: 'mdi-folder-plus',
                            label: 'patient_care_sheet',
                            resource: 'visit',
                            action: 'create',
                            behavior: 'remove',
                            condition: "!item.is_deleted && !item.is_archived",
                            method: (p, e) => careSheetService.patientCareSheet(p, e)
                        },
                        {
                            icon: 'mdi-certificate',
                            label: 'subscription_folder',
                            resource: 'contract',
                            action: 'get',
                            behavior: 'remove',
                            method: p => billingService.goToContract(p)
                        },
                        {
                            icon: 'mdi-clipboard-pulse-outline',
                            label: 'medical_care.scheduling_long',
                            resource: 'medical-care',
                            action: 'edit-scheduling',
                            behavior: 'remove',
                            method: p => medicalCareService.openSchedulingDialog(p.id,)
                        }
                    ],
                    multiple: [],
                    dynamicMultiple: []
                }
            });
        }

        static get $inject() {
            return [
                "frontDeskService", "patientService", "careSheetService", "$mdToast", "$translate", "$mdDialog", "visitService",
                "$state", "externalConnectionService", "configService", "billingService", "medicalCareService", "$scope"
            ];
        }

        $onInit() {
            this.configService.get("patient_list_grouped_tab")
                .then(groupedTabs => {
                    this.show(groupedTabs ? "all" : "active");
                    this.groupedTabs = groupedTabs;
                });
        }

        show(tab) {
            this.activatedTab = tab;
            this.actions['dynamicMultiple'] = [];
            this.scope.$applyAsync(() => this.actions['dynamicMultiple'] = this.multipleAction(tab));
            this.refresh({
                search: this.patientService.patientFilter(tab)
            }, true);
        }

        multipleAction(tab) {
            if (tab === "archived") {
                return [
                    this.unarchiveAction,
                    this.deleteAction,
                    this.privatizeAction,
                    this.unprivatizeAction
                ];
            } else if (tab === "deleted") return [this.restoreAction];
            else return [
                    this.archiveAction,
                    this.deleteAction,
                    this.privatizeAction,
                    this.unprivatizeAction,
                    this.sendSmsAction,
                ];
        }

        showToast(key) {
            const simpleToast = this.toast.simple()
                .hideDelay(1500)
                .position("bottom left")
                .textContent(this.translate.instant(key));

            this.toast.show(simpleToast);
        }

        archive(list) {
            this.promise = this.patientService.archivePatients(list)
                .then(() => {
                    this.showToast('patient_toast_archive');
                    this.refresh({
                        search: this.patientService.patientFilter(this.activatedTab)
                    }, true);
                });
        }

        unarchive(list) {
            this.promise = this.patientService.unarchivePatients(list)
                .then(() => {
                    this.showToast('patient_toast_unarchive');
                    this.refresh({
                        search: this.patientService.patientFilter(this.activatedTab)
                    }, true);
                });
        }

        remove(list) {
            const names = _.flatMap(list, "full_name").join("__BR__");

            const confirm = this.dialog.confirm()
                .title(this.translate.instant("patient_remove_confirm_title"))
                .htmlContent(_.replace(this.translate.instant("patient_remove_confirm_text", {names: `- ${names}`}), new RegExp("__BR__", "g"), "<br />- "))
                .ariaLabel('confirm')
                .targetEvent(null)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.patientService.removePatients(list)
                        .then(() => {
                            this.showToast('patient_toast_delete');
                            this.refresh({
                                search: this.patientService.patientFilter(this.activatedTab)
                            }, true)
                        });
                });
        }

        restore(list) {
            this.promise = this.patientService.restorePatients(list)
                .then(() => {
                    this.showToast('patient_toast_undelete');
                    this.refresh({
                        search: this.patientService.patientFilter(this.activatedTab)
                    }, true)
                });
        }

        privatize(list) {
            this.promise = this.patientService.privatizePatients(list)
                .then(() => {
                    this.showToast('patient_toast_privatized');
                    this.refresh({
                        search: this.patientService.patientFilter(this.activatedTab)
                    }, true);
                });
        }

        unprivatize(list) {
            this.promise = this.patientService.unprivatizePatients(list)
                .then(() => {
                    this.showToast('patient_toast_unprivatized');
                    this.refresh({
                        search: this.patientService.patientFilter(this.activatedTab)
                    }, true);
                });
        }

        sendSms(list, $event) {
            this.externalConnectionService.showSMSDialog($event, "send_patient_sms", _.map(list, 'id'));
        }
    }

    module.exports = PatientCtrl;

})();
