/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const angular = require('angular');

    const PatientCtrl = require("./controllers/PatientCtrl");
    const PatientFormCtrl = require("./controllers/PatientFormCtrl");

    const patientService = require("./services/patientService");
    const measureService = require("./services/measureService");
    const extApiService = require("./services/extApiService");

    const mnMedicalFile = require('./components/medical-file');
    const mnSummaryItem = require('./components/summary-item');
    const mnVaccination = require('./components/vaccination');
    const mnMeasurements = require('./components/measurements');
    const mnReferringContact = require('./components/referring-contact');

    const mnMeasurement = require('./directives/measurement');
    const mnMeasurementBlock = require('./directives/measurement-block');

    angular
        .module("medinet")

        .controller('PatientCtrl', PatientCtrl)
        .controller('PatientFormCtrl', PatientFormCtrl)

        .service("patientService", patientService)
        .service("measureService", measureService)
        .service("extApiService", extApiService)

        .component("mnMedicalFile", mnMedicalFile)
        .component("mnSummaryItem", mnSummaryItem)
        .component("mnVaccination", mnVaccination)
        .component("mnMeasurements", mnMeasurements)
        .component("mnReferringContact", mnReferringContact)

        .directive("mnMeasurementBlock", mnMeasurementBlock)
        .directive("mnMeasurement", mnMeasurement)


})();
