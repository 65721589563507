/**
 * Created by amine on 02/04/2018.
 */
(function () {
    'use strict';

    module.exports = PatientFusionCtrl;

    PatientFusionCtrl.$inject = ["maintenanceService", "$translate", "$mdDialog", "$mdToast", "frontDeskService"];

    function PatientFusionCtrl(maintenanceService, $translate, $mdDialog, $mdToast, frontDeskService) {
        let vm = this;

        vm.primaryCallback = patientCallback(vm.primary);
        vm.removePrimary = removePatient(vm.primary);
        vm.secondaryCallback = patientCallback(vm.secondary);
        vm.removeSecondary = removePatient(vm.secondary);
        vm.patientInfo = patientInfo;
        vm.validatePatients = validatePatients;
        vm.submit = submit;

        vm.$onInit = init;

        function init() {
            vm.primary = null;
            vm.secondary = null;
        }

        function patientCallback(model) {
            return _callback;

            function _callback(data) {
                model = data;
                if (_.get(vm.primary, "id", NaN) === _.get(vm.secondary, "id", NaN)) {
                    const simpleToast = $mdToast.simple()
                        .textContent($translate.instant("patient_fusion_same_patient"))
                        .position("bottom left")
                        .hideDelay(1500);

                    $mdToast.show(simpleToast);
                    vm.secondary = null;
                }
            }
        }

        function removePatient(model) {
            return _remove;

            function _remove() {
                model = null;
            }
        }

        function patientInfo(item, ev) {
            const locals = {
                'entry': null,
                'onlyResume': true,
                'patient-id': _.get(item, 'id')
            }

            frontDeskService.entryResume(locals, ev);
        }

        function validatePatients() {
            return !_.isNil(vm.primary) && !_.isNil(vm.secondary);
        }

        function submit() {
            const htmlContent = `<p>
                ${$translate['instant']("patient_fusion_confirm_msg_0")}&nbsp;
                <strong class='warn-msg'>${vm.primary.full_name}(${vm.primary.id})</strong>&nbsp;
                ${$translate['instant']("patient_fusion_confirm_msg_1")}&nbsp;
                <strong class='warn-msg'>${vm.secondary.full_name}(${vm.secondary.id})</strong>&nbsp;
                ${$translate['instant']("patient_fusion_confirm_msg_2")}&nbsp;
            </p>
            <p>${$translate['instant']("patient_fusion_confirm_title")}</p>`;

            const confirm = $mdDialog.confirm()
                .title($translate['instant']("patient_fusion_confirm_title"))
                .htmlContent(htmlContent)
                .ariaLabel('edit plan confirm')
                .targetEvent(null)
                .ok($translate['instant']('patient_fusion_confirm_ok'))
                .cancel($translate['instant']('cancel'));

            $mdDialog.show(confirm).then(success);

            function success() {
                maintenanceService.fusePatients(vm.primary, vm.secondary)
                    .then(success)

                function success() {
                    vm.secondary = null;
                    let simpleToast = $mdToast.simple()
                        .textContent($translate.instant("patient_fusion_success"))
                        .position("bottom left")
                        .hideDelay(1500);

                    $mdToast.show(simpleToast);

                }
            }

        }
    }
})();
