/**
 * Created by amine on 31/05/2021.
 */
(function () {
    'use strict';
    const PATHOLOGY_DIALOG = require('specifics/dialogs/pathologies-dialog');

    class PathologiesSetupCtrl {
        constructor(pathologyService, configService, $translate, $mdDialog, $mdToast, $q, $scope, dragulaService) {
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.q = $q;
            this.pathologyService = pathologyService;
            this.configService = configService;

            const consultationFieldsBagName = "consultation-fields-bag";
            dragulaService.options($scope, consultationFieldsBagName, {
                revertOnSpill: true,
                moves: (el, container, handle) => $(handle).is('.drag-handle') || $(handle).is('.drag-handle *')
            });
            const consultationFieldsBag = dragulaService.find($scope, consultationFieldsBagName).drake;
            consultationFieldsBag.on("dragend", () => {
                setTimeout(() => {
                    this.diagnosticsConfig.fields = _.cloneDeep(this.diagnosticsConfig.fields);
                    this.#_handleDiagnosticsConfig();
                }, 300);
            });

            const medicalFileFieldsBagName = "medical-file-fields-bag";
            dragulaService.options($scope, medicalFileFieldsBagName, {
                revertOnSpill: true,
                moves: (el, container, handle) => $(handle).is('.drag-handle') || $(handle).is('.drag-handle *')
            });
            const medicalFileFieldsBag = dragulaService.find($scope, medicalFileFieldsBagName).drake;
            medicalFileFieldsBag.on("dragend", () => {
                setTimeout(() => {
                    this.medicalFileConfig.diagnostics_fields = _.cloneDeep(this.medicalFileConfig.diagnostics_fields);
                    this.#_handleMedicalFileConfig();
                }, 300);
            });


            this.promise = null;
            this.pathologyTab = false;
            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.simpleToast = this.toast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(500);

            this.pathologies = [];
            this.data = [];
            this.total = 0;
            this.query = {
                limit: 15,
                page: 1,
                search_all: ""
            };

            this.successToast = () => this.toast.show(this.simpleToast);
            this.handleDiagnosticsConfig = () => this.#_handleDiagnosticsConfig();
            this.handleMedicalFileConfig = () => this.#_handleMedicalFileConfig();
            this.keyExist = (arr, value) => arr.includes(value);
        }

        static get $inject() {
            return ["pathologyService", "configService", "$translate", "$mdDialog", "$mdToast", "$q", "$scope", "dragulaService"];
        }

        get diagnosticsKeys() {
            return _.map(this.diagnosticsConfig.fields, "key");
        }

        get medicalFieldDiagnosticsKeys() {
            return _.map(this.medicalFileConfig.diagnostics_fields, "key");
        }

        $onInit() {
            this.onPaginate = (page, limit) => this._onPaginate(page, limit);
            this.getData();
        }

        handlePathology(pathology, event) {
            this.dialog.show(_.assign(PATHOLOGY_DIALOG, {
                targetEvent: event,
                locals: _.has(pathology, "id") ? {model: _.cloneDeep(pathology)} : {}
            })).then(result => {
                this.getData();
                this.query.search_all = result.name;
            }, _.noop);
        }

        removePathology(pathology) {
            this.pathologyService.removePathology(pathology)
                .then(() => this.getData(), _.noop);
        }

        getData() {
            this.promise = this.q.all([
                this.pathologyService.getPathologies(),
                this.configService.get(["diagnostics_config", "medical_file_config", "custom_classifications"])
            ]).then(([pathologies, {diagnostics_config, medical_file_config, custom_classifications}]) => {
                this.total = pathologies.length;
                this.initPathologies = pathologies;
                this.populateData(this.initPathologies);
                this.fieldsTitles = {
                    pathologies: "pathology_field_title",
                    icd: "icd_field_title",
                    osiics: "osiics_field_title"
                }

                this.diagnosticsConfig = diagnostics_config || {
                    fields: _.cloneDeep(this.pathologyService.DEFAULT_FIELDS)
                };
                this.medicalFileConfig = medical_file_config || {};
                this.customClassification = custom_classifications || {};
                if (!_.has(this.medicalFileConfig, "show_pathology_block")) {
                    this.medicalFileConfig.show_pathology_block = false;
                }
                if (!_.has(this.medicalFileConfig, "diagnostics_fields")) {
                    this.medicalFileConfig.diagnostics_fields = _.cloneDeep(this.pathologyService.DEFAULT_FIELDS);
                }

                if (this.total < ((this.query.page - 1) * this.query.limit))
                    this.query.page = 1;
            });
        }

        _onPaginate(page, limit) {
            page -= 1;
            this.data = _.slice(this.pathologies, page * limit, (page + 1) * limit)
        }

        populateData(data) {
            this.pathologies = _.orderBy(data, [path => path.searchable_value.toLowerCase()], ['asc']);
            this.query.page = 1;
            this._onPaginate(this.query.page, this.query.limit);
        }

        filteredData(newData) {
            this.populateData(newData);
        }

        addClassification(classification, medicalFile = false) {
            const field = {
                "key": classification.name,
                "label": classification.label,
                "enabled": true,
                "custom": true,
            }

            if (medicalFile) {
                _.pushOrUpdate(this.medicalFileConfig.diagnostics_fields, field, "key");
                this.#_handleMedicalFileConfig();
            } else {
                _.pushOrUpdate(this.diagnosticsConfig.fields, field, "key");
                this.#_handleDiagnosticsConfig();
            }
        }

        removeClassification(index, medicalFile = false) {
            if (medicalFile) {

            } else {
                this.diagnosticsConfig.fields.splice(index, 1);
                this.#_handleDiagnosticsConfig();
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        #_handleDiagnosticsConfig() {
            this.configService.set({"diagnostics_config": this.diagnosticsConfig})
                .then(() => this.successToast());
        }

        #_handleMedicalFileConfig() {
            this.configService.set({"medical_file_config": this.medicalFileConfig})
                .then(() => this.successToast());
        }
    }

    module.exports = PathologiesSetupCtrl;
})();