/**
 * Created by BETALOS on 10/01/2017.
 */
(function () {

    'use strict';

    module.exports = CurrentPatientCtrl;

    CurrentPatientCtrl.$inject = ["configService", "blockService", "$q", "$translate", "dragulaService", "$scope", "$mdToast"];

    function CurrentPatientCtrl(configService, blockService, $q, $translate, dragulaService, $scope, $mdToast) {
        let vm = this;

        const bag = "field-bag";
        const colorPickerParams = require('../json/color-picker-config.json');

        vm.$onInit = init;
        vm.addNewSummary = addNewSummary;
        vm.getFields = getFields;

        vm.consultationFields = [];
        vm.assignLabel = assignLabel;

        vm.handleType = handleType;
        vm.removeField = removeField;

        vm.handlePatientFields = _.mnDelay(handlePatientFields, 750);

        vm.handleSave = handleSave;
        vm.handleInit = handleInit;

        function init() {
            vm.fieldTypes = require('../json/summary-fields-type.json');
            vm.options = _.assign(colorPickerParams, {label: $translate.instant('color')});
            vm.patientAvailableFields = require('../json/current-patient-available-fields.json');

            const promises = [
                configService.get(["patient_summary_file", "patient_current_fields"], true),
                blockService.getBlockList('html'),
                blockService.get("5874bbe314f40d223498e5f9")
            ];

            vm.promise = $q.all(promises)
                .then(success);

            function success(data) {
                vm.summaryConfig = _.isNull(data[0]['patient_summary_file']) ? [] : data[0]['patient_summary_file'];
                vm.patientFields = _.isNull(data[0]['patient_current_fields']) ? {} : data[0]['patient_current_fields'];

                _.remove(data[1], {key: "5874bbe314f40d223498e5f9"});

                vm.consultationBlocks = data[1];
                vm.medicalFileFields = data[2]['html_fields'];
            }

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
        }

        function addNewSummary() {
            vm.summaryConfig.push({});
        }

        function getFields(block, index) {
            blockService.get(block)
                .then(success);

            function success(data) {
                vm.consultationFields[index] = data['html_fields'];
            }
        }

        function removeField(index) {
            vm.summaryConfig.splice(index, 1);
        }

        function assignLabel(summary, index) {
            let field = null;

            if (_.isUndefined(index)) field = _.find(vm.medicalFileFields, {key: summary['field_key']});
            else field = _.find(vm.consultationFields[index], {key: summary['field_key']});

            summary = _.assign(summary, _.pick(field, 'label'), {
                "value_key": field.type == "autocomplete" ? _.get(field, "meta_data.field_name") : "value"
            });

            return summary;
        }

        function handleType(summary) {
            if (summary.source == 'Exam' || summary.source == 'Prescription') _.assign(summary, {
                type: "url-list",
                label: $translate.instant(`summary_file_setup_${summary.source == 'Exam' ? 'exam' : 'prescription'}`),
            });

            else _.assign(summary, {type: "field"});
        }

        function handleSave() {
            configService.set({"patient_summary_file": vm.summaryConfig}, true)
                .then(success);

            function success() {
                const simpleToast = $mdToast.simple()
                    .hideDelay(1500)
                    .position("bottom left")
                    .textContent($translate.instant('summary_file_setup_edit_success'))

                $mdToast.show(simpleToast);
            }
        }

        function handlePatientFields() {
            configService.set({"patient_current_fields": vm.patientFields}, true)
                .then(success);

            function success() {
                const simpleToast = $mdToast.simple()
                    .hideDelay(1500)
                    .position("bottom left")
                    .textContent($translate.instant('current_patient_edit_success'));

                $mdToast.show(simpleToast);
            }
        }

        function handleInit(item, blockKey, index) {
            if (!_.isEmpty(item)) vm.getFields(blockKey, index);
        }

    }

})();