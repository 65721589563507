(function () {

    'use strict';

    // const {Chart, LineController, LineElement, PointElement, LinearScale, Title} = require('chart.js');

    // Chart.register(LineController, LineElement, PointElement, LinearScale, Title);

    const Chart = require('chart.js');

    const COLORS = [
        "#FF5722",
        "#43A047",
        "#03A9F4",
        "#009688",
        "#3F51B5",
        "#673AB7",
        "#F44336",
        "#9C27B0",
        "#E91E63",
    ]

    class MeasurementChartDialogCtrl {
        constructor($element, $mdDialog, measureService) {
            this.dialog = $mdDialog;
            this.measureService = measureService;

            const ctx = $("canvas", $element).get(0).getContext("2d");

            this.currentItem = null;
            this.patient = this.patient || null;
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: [],
                    labels: []
                },
                options: {
                    maintainAspectRatio: true
                }
            });
        }

        static get $inject() {
            return ["$element","$mdDialog", "measureService"];
        }

        $onInit() {
            this.promise = this.measureService.getMeasures()
                .then(data => {
                    this.items = _.filter(data, o => _.includes(['float', 'integer'], o.type));
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        choose(item) {
            if (this.currentItem && this.currentItem.id === item.id) return;

            this.currentItem = item;
            this.promise = this.measureService.getMeasureChart(this.patient, item)
                .then(data => {
                    let labels = _.map(data, (i) => moment(i.x).format('ll'));
                    let color = COLORS[Math.floor(Math.random() * COLORS.length)];
                    let label = item.unit ? `${item.name} (${item.unit.value})` : item.name;

                    this.chart.data.labels = labels;
                    this.chart.data.datasets = [
                        {
                            data, label,
                            borderColor: color,
                            backgroundColor: color,
                            fill: false
                        }
                    ];

                    this.chart.update();
                });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: MeasurementChartDialogCtrl,
        template: require("../views/measurement-chart-dialog.tpl"),
    };

})();
