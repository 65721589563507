/**
 * Created by BETALOS on 24/02/2017.
 */
(function () {
    'use strict';


    var mnBiologyModelsTable = {
        controller: mnBiologyModelsTableCtrl,
        controllerAs: "vm",
        bindings: {
            prescriptionDetails: '='
        },
        template: require('parameters/views/biology-models-table.tpl.html'), // or template,
    };

    mnBiologyModelsTableCtrl.$inject = [];

    function mnBiologyModelsTableCtrl() {
        var vm = this;

        vm.$onInit = init;
        vm.deleteDetail = deleteDetail;

        function init() {}

        function deleteDetail(item, uid) {
            if(_.isUndefined(uid)) _.remove(vm.prescriptionDetails, {exam: item.exam});

            else {
                _.unset(item.value, uid);
                if(_.size(item.value) == 0) _.remove(vm.prescriptionDetails, {exam: item.exam});
            }
        }

    }

    module.exports = mnBiologyModelsTable;

})();