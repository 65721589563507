/**
 * Created by amine on 23/09/2021.
 */
(function () {

    'use strict';

    const FIELDS = require("../json/external-patient-api-fields.json");
    const PATIENT_DIALOG_LIST = require("patient/dialogs/bulk-import-dialog");

    class BulkPatientImportCtrl {
        constructor(configService, externalConnectionService, $mdDialog, $mdToast, $q, Upload, $translate) {
            this.configService = configService;
            this.externalConnectionService = externalConnectionService;
            this.dialog = $mdDialog;
            this.q = $q;
            this.toast = $mdToast;
            this.upload = Upload;

            this.bulkImport = true;
            this.config = {};
            this.fields = [];

            this.simpleToast = $mdToast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
        }

        static get $inject() {
            return ["configService", "externalConnectionService", "$mdDialog", "$mdToast", "$q", "Upload", "$translate"];
        }

        get emptyFields() {
            return _.isNil(this.fields) || _.isEmpty(this.fields);
        }

        get availableFields() {
            return _.reject(this.allFields, item => {
                let hide = false;
                if (_.has(item, "active_module")) {
                    hide ||= !this.configService.checkActiveModule(item.active_module);
                }
                return _.includes(_.keys(this.fields), item.field) || hide
            });
        }

        $onInit() {
            const configKeys = ["patient_bulk_import_config", "patient_bulk_import_fields", "patient_custom_fields"];
            if (this.configService.checkActiveModule("has_technical_file")) configKeys.push("technical_file_fields");

            this.configService.get(configKeys)
                .then(({
                           patient_bulk_import_config,
                           patient_bulk_import_fields,
                           patient_custom_fields,
                           technical_file_fields
                       }) => {
                    this.allFields = this.externalConnectionService.getPatientAPIFields(patient_custom_fields, technical_file_fields);
                    this.config = patient_bulk_import_config || {};
                    this.fields = patient_bulk_import_fields || [];
                    if (this.emptyFields) {
                        this.fields = _.chain(FIELDS)
                            .cloneDeep()
                            .filter(["bulk_required", true])
                            .reduce((acc, item) => {
                                acc[item.field] = item;

                                return acc
                            }, {})
                            .value();
                    }
                });
        }

        uploadFile(file, ev) {
            if (!file) return false;

            const deferred = this.q.defer();
            const url = `/api/patient-bulk-import/upload/`;
            const promise = this.upload.upload({
                url: url,
                data: {
                    file_csv: file
                },
            })
                .then(response => {
                    const dialog = _.assign({}, PATIENT_DIALOG_LIST, {
                        targetEvent: ev,
                        locals: {
                            patients: response.data
                        }
                    });

                    this.dialog.show(dialog);
                }, deferred.reject);

            const toast = this.toast.mnAdvancedToast()
                .handle(promise)
                .label('bulk_patient_import.upload_title')
                .description('bulk_patient_import.upload_message');

            this.toast.show(toast);

            return deferred.promise;
        }

        submit() {
            this.configService.set({
                patient_bulk_import_config: this.config,
                patient_bulk_import_fields: this.fields
            }, false, null)
                .then((data) => this.toast.show(this.simpleToast));
        }

        addField(item) {
            this.fields[item.field] = _.cloneDeep(item);
        }

        removeField(name) {
            this.fields = _.omit(this.fields, name);
        }

        fieldEnabled(field) {
            return _.has(this.fields, field)
        }
    }

    module.exports = BulkPatientImportCtrl;

})();
