(function () {

    'use strict';

    const PROCEDURE_DIALOG = require('../dialogs/procedure-form/procedure-form-dialog');
    const CLINIC_LOCATION_DIALOG = require('../../hospitalization/dialogs/clinic-location/clinic-location.dialog');
    const INTERVENTION_REASON_DIALOG = require('../../hospitalization/dialogs/intervention-reason/intervention-reason.dialog');
    const HOSPITALIZATION_REASON_DIALOG = require('../../hospitalization/dialogs/hospitalization-reason/hospitalization-reason.dialog');

    class HospitalizationSetupCtrl {
        constructor($q, $mdDialog, $mdToast, $translate, configService, hospitalizationService, dragulaService, $scope) {
            this.q = $q;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.translate = $translate;
            this.configService = configService;
            this.hospitalizationService = hospitalizationService;

            this.hospitalizationReasons = [];
            this.interventionReasons = [];
            this.procedures = [];
            this.floors = [];
            this.rooms = [];
            this.beds = [];

            this.simpleToast = this.toast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            // procedure sort
            let bag = "item-bag";
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let endSortDelay = _.mnDelay(() => this.endSort(), 500);
            let drake = dragulaService.find($scope, bag).drake;

            drake.on("dragend", () => endSortDelay());
        }

        static get $inject() {
            return [
                '$q', '$mdDialog', '$mdToast', '$translate', 'configService', 'hospitalizationService', 'dragulaService',
                '$scope'
            ];
        }

        $onInit() {
            this.promise = this.q.all([
                this.hospitalizationService.getHospitalizationReasons(),
                this.hospitalizationService.getInterventionReasons(),
                this.hospitalizationService.getProcedures(),
                this.hospitalizationService.getFloors(),
                this.hospitalizationService.getRooms(),
                this.hospitalizationService.getBeds(),
            ]).then(data => {
                this.hospitalizationReasons = data[0];
                this.interventionReasons = data[1];
                this.procedures = data[2];
                this.floors = data[3];
                this.rooms = data[4];
                this.beds = data[5];
            });
        }

        // procedures related
        handleProcedure(item, ev) {
            this.dialog.show(_.assign({}, PROCEDURE_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(item),
                    url: '/api/hospitalization/procedure/'
                }
            })).then(data => {
                this.procedures = _.pushOrUpdate(this.procedures, data);
            });
        }

        removeProcedure(item, ev) {
            let confirm = this.dialog.confirm()
                .targetEvent(ev)
                .title(this.translate.instant('procedure_remove_confirm', {title: item.name}))
                .ariaLabel('remove location confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.hospitalizationService.removeProcedures(type, item)
                        .then(() => _.pull(this.procedures, item));
                });
        }

        endSort() {
            this.configService.set({"hospitalization_procedure_sort": this.getSortConfig()}, true)
                .then(() => this.toast.show(this.simpleToast));
        }

        getSortConfig() {
            return _.reduce(this.procedures, function (config, item, key) {
                return _.set(config, item.id.toString(), key);
            }, {})
        }

        // location related
        handleLocation(type, item, ev) {
            this.dialog.show(_.assign({}, CLINIC_LOCATION_DIALOG, {
                targetEvent: ev,
                locals: {
                    type, item: _.cloneDeep(item),
                }
            })).then(data => {
                if (type === 'floor') this.floors = _.pushOrUpdate(this.floors, data);
                else if (type === 'clinic-room') this.rooms = _.pushOrUpdate(this.rooms, data);
                else if (type === 'bed') this.beds = _.pushOrUpdate(this.beds, data);
            });
        }

        removeLocation(type, item, ev) {
            let confirm = this.dialog.confirm()
                .targetEvent(ev)
                .title(this.translate.instant(`hospitalization_setup.remove_confirm`, {title: item.name}))
                .ariaLabel('remove location confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.hospitalizationService.removeLocation(type, item)
                        .then(() => {
                            if (type === 'floor') _.pull(this.floors, item);
                            else if (type === 'clinic-room') _.pull(this.rooms, item);
                            else if (type === 'bed') _.pull(this.beds, item);
                        });
                });
        }

        // intervention related
        handleInterventionReason(item, ev) {
            this.dialog.show(_.assign({}, INTERVENTION_REASON_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(item)
                }
            })).then(data => {
                this.interventionReasons = _.pushOrUpdate(this.interventionReasons, data);
            });
        }

        removeInterventionReason(item, ev) {
            let confirm = this.dialog.confirm()
                .targetEvent(ev)
                .title(this.translate.instant(`hospitalization_setup.remove_confirm`, {title: item.description}))
                .ariaLabel('remove location confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.hospitalizationService.removeInterventionReason(item)
                        .then(() => _.pull(this.interventionReasons, item));
                });
        }

        // hospitalization reason
        handleHospitalizationReason(item, ev) {
            this.dialog.show(_.assign({}, HOSPITALIZATION_REASON_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(item)
                }
            })).then(data => {
                this.hospitalizationReasons = _.pushOrUpdate(this.hospitalizationReasons, data);
            });
        }

        removeHospitalizationReason(item, ev) {
            let confirm = this.dialog.confirm()
                .targetEvent(ev)
                .title(this.translate.instant(`hospitalization_setup.remove_confirm`, {title: item.description}))
                .ariaLabel('remove location confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.hospitalizationService.removeHospitalizationReason(item)
                        .then(() => _.pull(this.hospitalizationReasons, item));
                });
        }
    }

    module.exports = HospitalizationSetupCtrl;

})();
