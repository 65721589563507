/**
 * Created by amine on 12/04/2018.
 */
(function () {
    'use strict';

    const MILESTONE_DIALOG = require('specifics/dialogs/pregnancy-milestone-form-dialog');
    const EVENT_DIALOG = require('specifics/dialogs/pregnancy-event-form-dialog');

    class PregnancyCalendarSetupCtrl {
        constructor(pregnancyService, configService, dictionaryService, $translate, $mdDialog, $mdToast) {
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.pregnancyService = pregnancyService;
            this.configService = configService;

            this.promise = null;
            this.dialogContext = this.dialogContext || false;

            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.simpleToast = this.toast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(500);

            this.firstInit = true;
            this.dictionaryGroup = dictionaryService.getDictionaryGroups();
            this.fieldsConfig = {};
            this.configurations = [];
            this.listTotal = 0;
            this.listQuery = {
                limit: 15,
                page: 1,
                order: "+time_interval.week_count",
                search_all: ""
            };

            this.configModel = {};
        }

        static get $inject() {
            return ["pregnancyService", "configService", "dictionaryService", "$translate", "$mdDialog", "$mdToast"];
        }

        $onInit() {
            this.handleAutoSave = _.mnDelay(() => this._handleAutoSave(), 500);
            this.onConfigsReorder = order => this.onReorder(order);
            this.onConfigsPaginate = (page, limit) => this.onPaginate(page, limit);

            this.getConfigsData();
            this.configService.getByHttp("pregnancy_block")
                .then(data => {
                    this.configModel = data;
                });
        }


        handleConfiguration(config, event) {
            let dialog = null;
            switch (true) {
                case config.is_event:
                    dialog = EVENT_DIALOG;
                    break;
                case config.is_milestone:
                    dialog = MILESTONE_DIALOG;
                    break;
            }

            this.dialog.show(_.assign(dialog, {
                targetEvent: event,
                locals: _.has(config, "id") ? {config} : {}
            })).then(() => this.getConfigsData(), _.noop);
        }

        removeConfiguration(config) {
            this.pregnancyService.removeConfiguration(config)
                .then(() => this.getConfigsData(), _.noop);
        }

        getConfigsData() {
            this.promise = this.pregnancyService
                .configurationsList(this.listQuery)
                .then(data => {
                    this.listTotal = data.length;
                    this.configurations = data.list;

                    if (this.listTotal < ((this.listQuery.page - 1) * this.listQuery.limit))
                        this.listQuery.page = 1;
                });
        }

        onReorder(order) {
            this.configsQuery = _.assign(this.configsQuery, {order});
            this.getConfigsData();
        }

        onPaginate(page, limit) {
            this.configsQuery = _.assign(this.configsQuery, {page, limit});
            this.getConfigsData();
        }

        _handleAutoSave() {
            this.configService.set({pregnancy_block: this.configModel})
                .then(() => this.toast.show(this.simpleToast));
        }

        cancel() {
            if (this.dialogContext) this.dialog.cancel();
        }
    }

    module.exports = PregnancyCalendarSetupCtrl;
})();