(function () {

    "use strict";

    const DEFAULT_QUERY = {
        search: "",
        order: "last_name",
        page: 1,
        limit: 15
    };

    class ExtApiSearchDialogCtrl {
        constructor(extApiService, $mdDialog, $translate) {
            this.extApiService = extApiService;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.paginationLabel = {
                page: this.translate.instant('page'),
                rowsPerPage: this.translate.instant('rowsPerPage'),
                of: this.translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.list = [];
            this.total = 0;
            this.query = _.clone(DEFAULT_QUERY);
            this.promise = null;

            this.onReorder = order => this._onReorder(order);
            this.onPaginate = (page, limit) => this._onPaginate(page, limit);
        }

        static get $inject() {
            return ["extApiService", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.extraQuery = this.extraQuery || {};
            this.getData();
        }

        clearQuery() {
            this.query = _.clone(DEFAULT_QUERY);
        }

        getData() {
            return this.promise = this.extApiService
                .getList(this.query)
                .then(data => {
                    this.list = data.list;
                    this.total = data.length;
                }, err => {
                    console.error(err);
                })
        }

        select(item) {
            this.extApiService
                .persistPatient(item)
                .then(data => {
                    this.dialog.hide(data);
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        _onReorder(order) {
            this.query = _.assign(this.query, {order: order});
            this.getData();
        }

        _onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ExtApiSearchDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/ext-api-search-dialog.tpl.html"),
    };

})();