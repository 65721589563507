(function () {
    'use strict';

    class CloudPlatformsService {
        constructor($q, $http, mnWebSocket, $mdDialog) {
            this.q = $q;
            this.http = $http;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$mdDialog"];
        }

        getPlatforms() {
            const deferred = this.q.defer();
            const url = "/api/cloud-platform/";

            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        savePlatform(p) {
            let deferred = this.q.defer();
            let url = "/api/cloud-platform/";

            if (!_.isNil(p.id)) url += p.id + "/";
            this.http.post(url, p)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        deletePlatform(pk) {
            let deferred = this.q.defer();
            let url = `/api/cloud-platform/${pk}/`;

            this.http.delete(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        createPrismaOrder(patientId, ev) {
            this.dialog.show(_.assign({}, require('parameters/dialogs/prisma-order-dialog'), {
                targetEvent: ev,
                locals: {
                    patientId
                }
            }));
        }

        getDevices() {
            return this.ws.call("cloud_interfacing.CloudPlatform.get_devices_list");
        }

        assignDevice(sn, patientId, assignAction) {
            return this.ws.call("cloud_interfacing.CloudPlatform.assign_device", {sn, patientId, assignAction});
        }

        getAssignedDevices() {
            return this.ws.call("cloud_interfacing.CloudPlatform.get_assigned_devices");
        }

        getPatientExternalId(pk) {
            return this.ws.call("cloud_interfacing.CloudPlatform.get_patient_external_id", {pk});
        }

        importReportInstance(rootId, preSave, context, ev) {
            this.dialog.show(_.assign({}, require('parameters/dialogs/prisma-report-loading-dialog'), {
                targetEvent: ev,
                locals: {
                    patientId: rootId
                }
            }));
        }

        loadReport(patientExternalId, patientId, from_date, to_date) {
            return this.ws.call("cloud_interfacing.CloudPlatform.load_report", {patientExternalId, patientId, from_date, to_date});
        }

    }

    module.exports = CloudPlatformsService;

})();
