/**
 * Created by BETALOS on 22/02/2017.
 */
(function () {
    'use strict';


    var mnBiologyParametersTable = {
        controller: mnBiologyParametersTableCtrl,
        controllerAs: "vm",
        bindings: {
            biologyExam: '=',
            editCallBack: '&editItem',
            deleteCallBack: '&deleteDetail'
        },
        template: require('parameters/views/biology-parameters-table.tpl.html'), // or template,
    };

    mnBiologyParametersTableCtrl.$inject = [];

    function mnBiologyParametersTableCtrl() {
        var vm = this;

        vm.$onInit = init;

        function init() {}

    }

    module.exports = mnBiologyParametersTable;

})();