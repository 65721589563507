(function () {

    'use strict';

    class PrismaReportLoadingDialogCtrl {
        constructor($mdDialog, CloudPlatformsService, $q, $translate, system) {
            this.dialog = $mdDialog;
            this.CloudPlatformsService = CloudPlatformsService;
            this.system = system;
            this.q = $q;
            this.dateFormat = this.system['date_format'].js;
            this.translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "CloudPlatformsService", "$q", "$translate", "system"];
        }

        $onInit() {
            this.patientExternalId = null;
            this.assignedDevice = null;

            this.from_date= moment().startOf('month').format(this.dateFormat);

            this.promise = this.CloudPlatformsService.getPatientExternalId(this.patientId)
            .then(data => {
                this.patientExternalId = _.get(data, 'patient_external_id');
                this.assignedDevice = _.get(data, 'assigned_device');
            });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.CloudPlatformsService.loadReport(this.patientExternalId, this.patientId, this.from_date, this.to_date)
                .then(data => {
                    if (data) {
                        this.dialog.cancel();
                    } else {
                        this.dialog.show(
                            this.dialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent(this.translate.instant("cloud_platform_report_loading_error"))
                                .ariaLabel(_.get(data, 'error'))
                                .ok(this.translate.instant('ok'))
                                .multiple(true)
                        );
                    }
                });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PrismaReportLoadingDialogCtrl,
        template: require("../views/prisma-report-loading-dialog.tpl.html"),
    };

})();
