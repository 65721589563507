/**
 * Created by BETALOS on 07/01/2017.
 */
(function () {

    'use strict';

    module.exports = ConsultationSetupCtrl;

    ConsultationSetupCtrl.$inject = ["consultationService", "observationService", "listService", "$state", "$q", "configService", "$mdToast", "$translate", "$timeout"];

    function ConsultationSetupCtrl(consultationService, observationService, listService, $state, $q, configService, $mdToast, $translate, $timeout) {
        let vm = this;

        vm.$onInit = init;
        vm.handleConsultation = handleConsultation;

        vm.handleConfig = _.mnDelay(handleConfig, 750);

        function init() {
            vm.consultationTemplates = [];

            vm.promise = $q.all([
                consultationService.getConfigTable(),
                configService.get("consultation_config", true)])
                .then(success);

            function success(data) {
                vm.consultationTemplates = data[0];
                vm.config = data[1];
            }
        }

        function handleConsultation(template) {
            $state.go("app.parameters.consultation-setup-form", {
                template: _.isUndefined(template) ? null : template.id
            });
        }

        function handleConfig(template) {
            if (!_.isNil(template) && vm.config['disabled'][template.key]) {
                if (vm.config['consultation'] == template.key) vm.config['consultation'] = false;
                if (vm.config['control'] == template.key) vm.config['control'] = false;
            }
            if (vm.config['use_observation_as_default']) {
                vm.config['use_observation'] = false;
            }

            configService.set({"consultation_config": vm.config}, true)
                .then(success);

            function success() {
                const simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('exam_setup_edit_success'))
                    .position("bottom left")
                    .hideDelay(1500);

                $timeout(function () {
                    $mdToast.show(simpleToast);
                }, 100);

            }
        }
    }

})();