(function () {
    'use strict';

    module.exports = OrganizationCtrl;

    OrganizationCtrl.$inject = ["$mdDialog", "organizationService", "$translate"];

    function OrganizationCtrl($mdDialog, organizationService, $translate) {
        let vm = this;

        vm.$onInit = init;
        vm.handleItem = handleItem;
        vm.removeOrganization = removeOrganization;

        let dialog = {
            controller: DialogController,
            controllerAs: "vm",
            template: require("shared/views/organization-dialog.tpl.html"),
            parent: $(document.body),
            locals: {},
            clickOutsideToClose: true,
            bindToController: true,

        };

        function init() {
            vm.organizations = [];
            loadOrganizations();
        }

        function loadOrganizations() {
            vm.promise = organizationService.getOrganizations().then(success);

            function success(data) {
                vm.organizations = data;
            }
        }

        function handleItem(item, ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {model: _.cloneDeep(item)}
            })).then(done);
        }

        function removeOrganization(item, ev) {
            const confirm = $mdDialog.confirm()
                .title($translate['instant']('organization_remove_confirm', {title: item.name}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = organizationService.removeOrganization(item)
                    .then(done)
            }

            function done() {
                _.pull(vm.organizations, item);
            }
        }

        function done(data) {
            vm.organizations = _.pushOrUpdate(vm.organizations, data);
        }
    }

    DialogController.$inject = ["$mdDialog", "organizationService"];

    function DialogController($mdDialog, organizationService) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;

        vm.cancel = $mdDialog.cancel;

        function init() {
            if (_.isNil(vm.model)) vm.model = {contact_info: {phone_numbers: []}};
            else if (_.isNil(vm.model.contact_info)) vm.model.contact_info = {phone_numbers: []};
        }

        function submit() {
            organizationService.saveOrganization(vm.model).then($mdDialog.hide);
        }
    }

})();