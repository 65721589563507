(function () {

    "use strict";

    const DEFAULT_QUERY = {
        search: "",
        order: "last_name",
        page: 1,
        limit: 15
    };

    class BulkImportDialogCtrl {
        constructor(extApiService, configService, $mdDialog, $translate) {
            this.extApiService = extApiService;
            this.configService = configService;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.paginationLabel = {
                page: this.translate.instant('page'),
                rowsPerPage: this.translate.instant('rowsPerPage'),
                of: this.translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.initPatients = []
            this.config = null;
            this.total = 0;
            this.query = _.clone(DEFAULT_QUERY);
            this.promise = null;

            this.onReorder = order => this._onReorder(order);
            this.onPaginate = (page, limit) => this._onPaginate(page, limit);
        }

        static get $inject() {
            return ["extApiService", "configService", "$mdDialog", "$translate"];
        }

        get list() {
            const pageIndex = this.query.page - 1
            const start = (pageIndex * this.query.limit);

            return _.chain(this.patients)
                .orderBy(this.query.orderKey, this.query.orderDir)
                .slice(start, start + this.query.limit)
                .value() || [];

        }

        $onInit() {
            this.patients = this.patients || [];
            this.promise = this.configService.get("patient_bulk_import_config")
                .then(data => {
                    this.config = data;
                    this.total = this.patients.length;
                    this.initPatients = _.cloneDeep(this.patients);
                });
            // this.extraQuery = this.extraQuery || {};
        }

        clearQuery() {
            this.query = _.clone(DEFAULT_QUERY);
        }

        submit() {
            this.extApiService
                .persistPatients(this.initPatients)
                .then(data => {
                    this.dialog.hide(data);
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        filterData() {
            const key = _.chain(this.query.search).toLower().deburr().value();

            this.query.page = 1;
            if (!!_.size(key)) {
                this.patients = _.chain(this.initPatients)
                    .cloneDeep()
                    .filter(item => {
                        const firstName = _.chain(item.first_name).toLower().deburr().value();
                        const lastName = _.chain(item.last_name).toLower().deburr().value();
                        const birthDate = _.chain(item.birth_date).toLower().deburr().value();
                        const fileNumber = _.chain(item.file_number).toLower().deburr().value();

                        return _.includes(firstName, key) || _.includes(lastName, key) || _.includes(birthDate, key) || _.includes(fileNumber, key);
                    })
                    .value();
            } else {
                this.patients = _.chain(this.initPatients);
            }
        }

        _onReorder(order) {
            const orderDir = this.query.order.includes("-") ? "desc" : "asc";
            const orderKey = order.replace("-", "");

            this.query = _.assign(this.query, {order, orderDir, orderKey});
        }

        _onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: BulkImportDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/bulk-impot-dialog.tpl.html"),
    };

})();