(function () {
    "use strict";

    module.exports = {
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PatientAlertsDialogCtrl,
        template: require("patient/views/patient-alerts-dialog.tpl.html"),
    };

    PatientAlertsDialogCtrl.$inject = ["$mdDialog", "notificationService"];

    function PatientAlertsDialogCtrl($mdDialog, notificationService) {
        let vm = this;

        vm.alerts = [];

        vm.triggers = {
            "WR": "alert_trigger_wr",
            "SV": "alert_trigger_sv",
            "EV": "alert_level_ev",
        }

        vm.$onInit = init;

        vm.handleAlert = handleAlert;
        vm.removeAlert = removeAlert;

        vm.cancel = $mdDialog.hide;

        function init() {
            vm.promise = notificationService.getPatientAlerts(vm.patient)
                .then(success);

            function success(alerts) {
                vm.alerts = alerts;
            }
        }

        function handleAlert(alert, ev) {
            notificationService.alertDialog(vm.patient, null, alert, ev)
                .then(success);


            function success(alert) {
                vm.alerts = _.pushOrUpdate(vm.alerts, alert);
            }
        }

        function removeAlert(alert, ev) {
            notificationService.removeAlert(alert, ev)
                .then(success);

            function success() {
                vm.alerts = _.without(vm.alerts, alert);
            }
        }
    }


})();