(function () {

    'use strict';

    const DRAG_BAG = "field-bag";

    class ChoiceListSetupCtrl {
        constructor($scope, blockService, dragulaService, $mdToast, $translate) {
            this.scope = $scope;
            this.service = blockService;
            this.dragulaService = dragulaService;
            this.toast = $mdToast;

            this.simpleToast = this.toast.simple()
                .hideDelay(2000)
                .position("bottom left")
                .textContent($translate['instant']('exam_setup_edit_success'));
        }

        static get $inject() {
            return ["$scope", "blockService", "dragulaService", "$mdToast", "$translate"];
        }

        $onInit() {
            this.service.choicesSubject
                .subscribe(data => {
                    this.fields = _.filter(data, ['type', 'choice-list']);

                    if (this.fields.length > 0) this.currentField = _.head(this.fields);
                    else this.createNewField();
                });

            this.dragulaService.options(this.scope, DRAG_BAG, {
                revertOnSpill: false,
                moves: (el, container, handle) => {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
        }

        createNewField() {
            this.currentField = {
                type: 'choice-list',
                meta_data: {
                    is_multiple: true,
                    choices: []
                }
            }
        }

        chooseField(field) {
            this.currentField = _.cloneDeep(field);
        }

        addChoice() {
            this.currentField['meta_data']['choices'].push({});
        }

        removeChoice(index) {
            this.currentField['meta_data']['choices'].splice(index, 1);
        }

        handleChoice() {
            this.promise = this.service.handleChoiceListFields(this.currentField)
                .then(data => {
                    this.fields = _.chain(this.fields).pushOrUpdate(data, 'key').sortBy('label').value();
                    this.currentField = data;

                    this.service.choicesSubject.next(this.fields);
                    setTimeout(() => this.toast.show(this.simpleToast), 100);
                });
        }

    }

    module.exports = ChoiceListSetupCtrl;

})();
