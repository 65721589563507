/**
 * Created by amine on 04/12/2018.
 */
(function () {

    'use strict';

    class CareSheetPrintingCtrl {
        constructor(careSheetService, $state) {
            this.careSheetService = careSheetService;
            this.$state = $state;

            this.templates = []
        }

        static get $inject() {
            return ["careSheetService", "$state"];
        }

        $onInit() {
            this.loadTemplates();

            this.careSheetService.templateChanged.subscribe(() => {
                this.loadTemplates();
            })
        }

        loadTemplates() {
            this.promise = this.careSheetService.getCareSheetTemplates().then(data => this.templates = data);
        }
        
        newTemplate() {
            this.careSheetService.templateFormState(null, true);
        }

        editTemplate(template) {
            this.careSheetService.templateFormState(template, true);
        }
    }


    module.exports = CareSheetPrintingCtrl;

})();
