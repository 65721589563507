(function () {

    'use strict';

    //const LIST_ITEMS = require('../json/list-models.json');
    const ENTRY_TABS = require('../json/entry-resume-tabs.json');
    const FIELDS_PARAMS = require('../json/patient-fields-params.json');
    const PATIENT_TABLET_FIELDS = require('../json/tablet-patient-fields.json');
    const AVAILABLE_COLUMNS = require('../json/patient-available-columns.json');

    class PatientFileSetupCtrl {
        constructor(
            $scope, dragulaService, $mdDialog, $translate, patientService, tableService, $mdToast,
            dictionaryService, configService, listService
        ) {
            this.scope = $scope;
            this.toast = $mdToast;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.tableService = tableService;
            this.configService = configService;
            this.dragulaService = dragulaService;
            this.patientService = patientService;

            // global related
            this.resumeTabs = ENTRY_TABS;
            this.default = _.cloneDeep(this.configService.defaultValues);
            this.dictionaryGroup = dictionaryService.getDictionaryGroups();

            this.updateDefault = _.debounce(() => this.startAutoSave(), 500);
            this.entryResumeTab = _.debounce(() => this.handleEntryResumeTabFunc(), 500);
            this.handlePatientAlertField = _.debounce((clear = false) => this.handlePatientAlertFieldFunc(clear), 500);
            this.handleNotificationField = _.debounce((clear = false) => this.handleNotificationFieldFunc(clear), 500);
            this.handleMedicalFileConfig = _.debounce((clear = false) => this.handleMedicalFileConfigFunc(clear), 500);
            this.handlePatientIdentifierConfig = _.debounce((clear = false) => this.handlePatientIdentifierConfigFunc(clear), 500);

            if (!configService.isDental()) {
                _.remove(this.resumeTabs, 'isDental');
            }

            // patient form fields
            this.patientFormFields = _.cloneDeep(PATIENT_TABLET_FIELDS);

            // column related
            this.columnsConfig = [];
            this.customColumns = [];
            this.availableColumns = _.chain(AVAILABLE_COLUMNS).cloneDeep().filter(['is_required', false]).value();

            this.simpleToast = this.toast.simple()
                .hideDelay(2000)
                .position("bottom left")
                .textContent($translate.instant('exam_setup_edit_success'));

            this.identifierToast = this.toast.simple()
                .hideDelay(2000)
                .position("bottom left")
                .textContent($translate.instant('general_patient_identifier_fix_identifiers_success'));
        }

        static get $inject() {
            return [
                '$scope', 'dragulaService', '$mdDialog', '$translate', 'patientService', 'tableService', '$mdToast',
                'dictionaryService', 'configService', 'listService'
            ];
        }

        $onInit() {
            this.fields = _.cloneDeep(this.patientService.customFields);
            this.customColumns = _.map(this.fields, field => this.customFieldToColumn(field));
            this.patientIdentifierConfig = this.patientService.uniqueIdentifierConfig;

            this.tableService.getColumns('patient.PatientTable', true)
                .then(config => {
                    this.columnsConfig = config;
                    this.columnsConfig.columns = _.sortBy(this.columnsConfig.columns, 'order');
                });


            this.promise = this.configService.get([
                "patient_alert_field", "notification_field", "entry_resume_config", "medical_file_config",
                "tablet_patient_fields"
            ], true).then(data => {
                this.patientAlertField = data['patient_alert_field'];
                this.notificationField = data['notification_field'];
                this.medicalFileConfig = data['medical_file_config'];
                this.entryResumeConfig = data['entry_resume_config'] || {default_tab: 'PATIENT_DEMOGRAPHICS'};
                this.tabletPatientFields = data['tablet_patient_fields'] || {
                    last_name: true,
                    first_name: true,
                    birth_date: true
                };
            });
        }

        // general related
        startAutoSave() {
            this.configService.set({"default_values": this.default}, true)
                .then(() => {
                    this.configService.defaultValues = _.cloneDeep(this.default);
                    this.saveMsg();
                });
        }

        handlePatientAlertFieldFunc(clear = false) {
            if (clear) this.patientAlertField = null;
            this.configService.set({"patient_alert_field": this.patientAlertField}, true)
                .then(() => this.saveMsg());
        }

        handleEntryResumeTabFunc() {
            this.configService.set({"entry_resume_config": this.entryResumeConfig}, true)
                .then(() => this.saveMsg());
        }

        handleNotificationFieldFunc(clear) {
            if (clear) this.notificationField = null;
            this.configService.set({"notification_field": this.notificationField}, true)
                .then(() => this.saveMsg());
        }

        handleMedicalFileConfigFunc() {
            this.configService.set({"medical_file_config": this.medicalFileConfig})
                .then(() => this.saveMsg());
        }

        handlePatientIdentifierConfigFunc(clear) {
            if (clear) this.notificationField = {
                is_enabled: false,
                type: "auto",
                format: "F{id}/{creation_year}"
            };
            this.patientService.setIdentifierConfig(this.patientIdentifierConfig)
                .then(() => this.saveMsg());
        }

        // fields related
        saveFields() {
            this.customColumns = _.map(this.fields, field => this.customFieldToColumn(field));

            this.promise = this.patientService.setCustomFields(this.fields)
                .then(() => this.saveMsg());
        }

        addNewField() {
            this.fields.push(
                _.cloneDeep(FIELDS_PARAMS.emptyField)
            )
        }

        // patient fields related
        saveTabletFields() {
            this.configService.set({"tablet_patient_fields": this.tabletPatientFields})
                .then(() => this.saveMsg());
        }

        // column related
        saveColumns() {
            _.forEach(this.columnsConfig.columns, (v, k) => v.order = k);

            this.promise = this.tableService.updateColumns('patient.PatientTable', this.columnsConfig.columns, this.columnsConfig.default_sort)
                .then(() => this.saveMsg());
        }

        removeColumn(index) {
            this.columnsConfig.columns.splice(index, 1);
        }

        isColumnExist(column) {
            return _.find(this.columnsConfig.columns, ['order_by', column.order_by]);
        }

        handleColumn(column) {
            let existence = this.isColumnExist(column);

            if (existence) this.columnsConfig.columns = _.without(this.columnsConfig.columns, existence);
            else this.columnsConfig.columns.push(column);
        }

        customFieldToColumn(field) {
            return _.assign({
                "label": field.label,
                "order_by": `custom_fields.${field.slug}`,
                "type": "custom",
                "is_orderable": true,
                "is_shown": true,
                "is_searchable": true,
                "is_editable": false,
                "is_required": false,
                "is_global_searchable": false,
                "is_ref": false
            }, field.type === 'select' ? {
                "order_by": `custom_fields.${field.slug}.value`, is_orderable: false,
                is_ref: true, model: field.selectProvider, module: "shared.lists",
            } : {});
        }

        saveMsg() {
            setTimeout(() => this.toast.show(this.simpleToast), 50);
        }

        generateIdentifiers(regenerate = false) {
            return this.patientService.generateIdentifiers(regenerate)
                .then((a) => setTimeout(() => {
                    if (a) this.toast.show(this.identifierToast)
                }, 50));
        }
    }

    module.exports = PatientFileSetupCtrl;

})();
