/**
 * Created by amine on 04/12/2018.
 */
(function () {
    'use strict';

    class CSPrintingFormCtrl {
        constructor(careSheetService, $element, $mdDialog, $state, $transition$) {
            this.careSheetService = careSheetService;
            this.$element = $element;
            this.$mdDialog = $mdDialog;
            this.$state = $state;

            this.template = {
                default_font_size: 18,
                pages: [{
                    configuration: {},
                    image: null
                }]
            }
            this.selectedPage = 0;
            this.templateId = _.get($transition$.params('to'), 'id');
        }

        static get $inject() {
            return ["careSheetService", "$element", "$mdDialog", "$state", "$transition$"];
        }

        $onInit() {
            if (_.isNil(this.templateId)) this._fixAria();
            else this.careSheetService.getCareSheetTemplate(this.templateId).then(data => {
                this.template = data;
                this._fixAria();
                this.selectedPage = 0;
            });
        }

        pageSelected($index) {
            this.selectedPage = $index;
        }

        addPage() {
            this.template.pages.push({
                configuration: {},
                image: null
            })
            this.pageSelected(this.template.pages.length - 1)
        }

        removePage() {
            this.template.pages.splice(this.selectedPage, 1);
            if (this.template.pages.length > 0) this.selectedPage--;
            else this.addPage()
        }

        submit() {
            this.careSheetService.saveCareSheetTemplate(this.template)
                .then(data => {
                    this.template = data;
                    this.careSheetService.templateFormState(data, false);
                    this.careSheetService.templateChanged.next(true);
                });
        }

        _fixAria() {
            setTimeout(() => $("button:not([aria-label])", this.$element).attr("aria-label", "btn"), 0)
        }

        visualizeImage(image, $ev) {
            if (_.isNil(image)) return false;

            const dialog = require('stand-alone/file-manager/dialogs/visualize-file-dialog');
            const file = {
                name: `#${this.selectedPage + 1}`,
                content: image,
                isBase64: true,
                mime: "image/*"
            };

            this.$mdDialog.show(_.assign(dialog, {
                targetEvent: $ev,
                locals: {
                    files: [file],
                    fileIndex: 0,
                    onlyOne: true,
                    allowEdit: false,
                    allowDownload: false
                }
            }));
        }

        uploadImage(page, $ev) {
            const dialog = require('parameters/dialogs/printing-image-dialog');

            this.$mdDialog.show(_.assign({}, dialog, {
                targetEvent: $ev,
                locals: {
                    multiple: false,
                    noTitle: true
                }
            })).then(files => {
                let file = _.get(files, "0.content");

                if (file) page.image = file;
            });
        }

        duplicateTemplate() {
            this.careSheetService.duplicateCareSheetTemplate(this.template.id)
                .then((data) => {
                    this.careSheetService.templateFormState(data, true);
                    this.careSheetService.templateChanged.next(true);
                });
        }

        removeTemplate() {
            this.careSheetService.removeCareSheetTemplate(this.template.id)
                .then(() => {
                    this.careSheetService.templateFormState(null, true);
                    this.careSheetService.templateChanged.next(true);
                });
        }
    }


    module.exports = CSPrintingFormCtrl;

})();
