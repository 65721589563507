(function () {

    'use strict';

    class AppBackupDialogCtrl {
        constructor($mdDialog, configService) {
            this.dialog = $mdDialog;
            this.configService = configService;

            this.types = [
                {value: "NO_FILES", label: "app_backup.no_files_backup"},
                {value: "ONLY_FILES", label: "app_backup.only_files_backup"},
                {value: "FULL", label: "app_backup.full_backup"}
            ]
        }

        static get $inject() {
            return ["$mdDialog", "configService"];
        }

        $onInit() {
            this.configService.getByHttp("backups_config")
                .then(data => {
                    this.backup = {
                        backup_type: _.get(data, "default_type", "NO_FILES")
                    }
                });

        }

        cancel() {
            this.dialog.cancel();
        }

        generate() {
            this.dialog.hide(_.cloneDeep(this.backup));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AppBackupDialogCtrl,
        template: require("../views/app-backup-dialog.tpl.html"),
    };


})();