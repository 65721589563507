/**
 * Created by BETALOS on 29/12/2016.
 */
(function () {

    'use strict';

    module.exports = EstablishmentCtrl;

    EstablishmentCtrl.$inject = [];

    function EstablishmentCtrl() {
        var vm = this;

        vm.$onInit = init;

        function init() {}

    }

})();
