/**
 * Created by BETALOS on 04/07/2017.
 */
(function () {

    'use strict';

    const RESOURCE_CHOICES = [
        {key: 'create', label: 'acl_default_actions.create'},
        {key: 'get', label: 'acl_default_actions.get'},
        {key: 'update', label: 'acl_default_actions.update'},
        {key: 'delete', label: 'acl_default_actions.delete'}
    ];

    const ACCESS_LEVELS = [
        {key: 'none', label: 'acl_levels.none'},
        {key: 'mine', label: 'acl_levels.mine'},
        {key: 'service', label: 'acl_levels.service'},
        {key: 'all', label: 'acl_levels.all'},
    ];

    const PROFILE_RESOURCES = require('../json/resources.json');

    class ProfileSetupFormCtrl {
        constructor(authService, $transition$, $state, $mdToast, $translate, $timeout) {
            this.$state = $state;
            this.toast = $mdToast;
            this.$timeout = $timeout;
            this.authService = authService;

            this.accessLevels = ACCESS_LEVELS;
            this.currentProfile = _.get($transition$.params('to'), 'profile');
            this.examSetupEditSuccess = $translate.instant('exam_setup_edit_success');

            this.filteredResources = [];
            this.resources = _.chain(PROFILE_RESOURCES)
                .map(item => _.assign(item, {translate: $translate.instant(item.label)}))
                .sortBy('translate')
                .value();
        }

        static get $inject() {
            return ["authService", "$transition$", "$state", "$mdToast", "$translate", "$timeout"];
        }

        $onInit() {
            this.promise = this.authService.getProfile(this.currentProfile)
                .then(data => {
                    this.profile = data;

                    this.profile.permissions = _.reduce(this.resources, (permissions, resource) => {
                        if (_.has(permissions, resource.key)) return permissions
                        else return _.assign(permissions, {[resource.key]: {name: resource.key, is_all: false, actions: {}}});
                    }, _.isEmpty(this.profile.permissions) ? {} : this.profile.permissions);
                });
        }


        goBack() {
            this.$state.go('app.parameters.profile-setup');
        }

        handleSave() {
            this.promise = this.authService.handleProfile(this.profile)
                .then(() => {
                    let simpleToast = this.toast.simple()
                        .textContent(this.examSetupEditSuccess)
                        .position("bottom left")
                        .hideDelay(1500);

                    this.$timeout(() => this.toast.show(simpleToast), 100);
                });
        }

        getResourceChoices(resource) {
            return _.concat(RESOURCE_CHOICES, _.get(resource, 'extra-actions', []));
        }

    }

    module.exports = ProfileSetupFormCtrl;

})();
