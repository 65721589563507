
(function () {

    'use strict';

    class CloudPlatformsCtrl {
        constructor(CloudPlatformsService,$mdDialog) {
            this.CloudPlatformsService = CloudPlatformsService;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["CloudPlatformsService","$mdDialog"];
        }

        $onInit() {
            this.loadPlatforms();
        }
        loadPlatforms() {
            this.promise = this.CloudPlatformsService.getPlatforms()
                .then(data => this.platforms = data);
        }
        handlePlatform(p) {
            this.$mdDialog.show(_.assign(require('parameters/dialogs/cloud-platform-dialog'), {
                locals: {
                    platform: p
                }
            })).then(this.loadPlatforms.bind(this));
        }
        removePlatform(p) {
            this.promise=this.CloudPlatformsService.deletePlatform(_.get(p,'id'))
                            .then(this.loadPlatforms.bind(this));

        }
        editPlatformDevices(){
            this.$mdDialog.show(_.assign({}, require('parameters/dialogs/prisma-devices-list-dialog'), {
                locals: {
                }
            }));
        }

    }

    module.exports = CloudPlatformsCtrl;

})();
