/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {

    'use strict';

    const STAFF_DIALOG = require('auth/dialogs/staff-dialog');
    const SERVICE_DIALOG = require('auth/dialogs/service-dialog');

    class AccountsSetupCtrl {
        constructor($q, actorService, $mdDialog, authService, system) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.authService = authService;
            this.actorService = actorService;

            this.totalAccounts = system['allowed_accounts'];
        }

        static get $inject() {
            return ["$q", "actorService", "$mdDialog", "authService", "system"];
        }

        $onInit() {
            this.promise = this.$q.all([
                this.actorService.getServices(),
                this.actorService.getStaffList()
            ]).then(data => {
                this.services = data[0];
                this.staffs = _.orderBy(data[1], item => [item.is_active ? 0 : 1, _.lowerCase(item.full_name)], ['asc', 'asc'])
            });
        }

        handleAccount(staff, ev) {
            if (this.getAvailable() === 0 && !staff) return;

            this.dialog.show(_.assign({}, STAFF_DIALOG, {
                targetEvent: ev,
                locals: {
                    staff: _.cloneDeep(staff)
                }
            })).then(data => {
                this.staffs = _.chain(this.staffs)
                    .pushOrUpdate(data)
                    .orderBy(item => [item.is_active ? 0 : 1, _.lowerCase(item.full_name)], ['asc', 'asc'])
                    .value();
            });
        }

        getAvailable() {
            return this.totalAccounts - _.sumBy(this.staffs, staff => staff.is_active ? 1 : 0);
        }

        // service related
        handleService(service, ev) {
            this.dialog.show(_.assign({}, SERVICE_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(service)
                }
            })).then(data => this.services = _.pushOrUpdate(this.services, data));
        }
    }

    module.exports = AccountsSetupCtrl;

})();
