/**
 * Created by BETALOS on 21/02/2017.
 */
(function () {

    'use strict';

    module.exports = biologyService;

    const {Subject} = require("rxjs");

    biologyService.$inject = ["$q", "$http"];

    function biologyService($q, $http) {
        let self = this;

        self.examSubject = new Subject();

        self.getBiologyExams = getBiologyExams;
        self.handleBiologyExam = handleBiologyExam;
        self.removeBiologyExam = removeBiologyExam;
        self.editBiologyExamTitle = editBiologyExamTitle;

        function getBiologyExams() {
            const deferred = $q.defer();
            const url = "/api/biology-exam/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleBiologyExam(biologyExam) {
            const deferred = $q.defer();
            const url = `/api/biology-exam/${biologyExam.id ? biologyExam.id + '/' : ''}`;

            $http.post(url, biologyExam)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
                self.examSubject.next(response.data);
            }

            return deferred.promise;
        }


        function removeBiologyExam(biologyExam) {
            const url = `/api/biology-exam/${biologyExam.id}/`;
            return $http.delete(url);
        }


        function editBiologyExamTitle(biologyExam, title) {
            const deferred = $q.defer();
            const url = `/api/biology-exam/${biologyExam}/`;

            $http.put(url, title)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
                self.examSubject.next(response.data);
            }

            return deferred.promise;
        }
    }

})();
