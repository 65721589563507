/**
 * Created by Maria on 25/09/2017. Edited by Amine 04/10/2021
 */
(function () {

    'use strict';

    class ProceduresCatalogCtrl {
        constructor(catalogService, $mdDialog) {
            this.catalogService = catalogService;
            this.dialog = $mdDialog;

            this.catalogs = [];
            this.selectedCatalog = {procedures: []};
            this.promise = null;
            //vm.is_valid_name = true;
            //vm.validation = {valid: true, invalid: false};
        }

        static get $inject() {
            return ["catalogService", "$mdDialog"];
        }

        $onInit() {
            this.promise = this.catalogService.getCatalogs()
                .then(data => {
                    this.catalogs = data;
                    this.selectCatalog(_.get(this.catalogs, "0"));
                });
        }

        addItem(obj, ev) {
            this.selectedCatalog = {procedures: []};
        }

        selectCatalog(item = null) {
            if (item == null) {
                this.selectedCatalog = {procedures: []};
            } else {
                this.selectedCatalog = _.cloneDeep(item);
                this.selectedCatalog.procedures = _.filter(this.selectedCatalog.procedures, procedure => !!procedure);
            }
        }

        submit() {
            this.catalogService.saveCatalog(this.selectedCatalog)
                .then(data => {
                    this.selectedCatalog = data;
                    _.pushOrUpdate(this.catalogs, this.selectedCatalog);
                });
        }

        loadProcedures(data) {
            data.forEach(procedure => _.pushOrUpdate(this.selectedCatalog.procedures, procedure));
        }

        removeProcedure(index) {
            this.selectedCatalog.procedures.splice(index, 1);
        }

        deleteCatalog() {
            this.catalogService.deleteCatalog(this.selectedCatalog)
                .then(() => {
                    _.remove(this.catalogs, ["id", this.selectedCatalog.id]);
                    this.selectCatalog()
                });
        }

        checkUnique() {
            return _.chain(this.catalogs)
                .map("name")
                .filter(item => item.id !== _.get(this.selectedCatalog, "id"))
                .includes(this.selectedCatalog.name)
                .value();
        }

    }

    module.exports = ProceduresCatalogCtrl;

})();