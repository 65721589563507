(function () {

    'use strict';

    const TEMPLATE_URL = '/api/tablet-survey-template/';

    class TabletSurveyCtrl {
        constructor($http, $mdDialog, $translate) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.survey = null;
            this.questionTypes = [
                {
                    label: "tablet_survey_question_text",
                    value: "TEXT"
                },
                {
                    label: "tablet_survey_question_yes_no",
                    value: "BOOLEAN"
                },
                {
                    label: "tablet_survey_question_choices",
                    value: "CHOICES"
                },
                {
                    label: "tablet_survey_question_multiple_choices",
                    value: "MULTIPLE_CHOICES"
                }
            ]
        }

        static get $inject() {
            return ["$http", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.promise = this.http.get(TEMPLATE_URL)
                .then(response => this.items = response.data, null);
        }

        submit() {
            this.promise = this.http.post(`${TEMPLATE_URL}${this.survey.id ? this.survey.id + '/' : ''}`, this.survey)
                .then(response => {
                    this.survey = response.data;
                    this.items = _.pushOrUpdate(this.items, this.survey);
                }, null)
        }

        addSurvey() {
            this.survey = {questions: []};
        }

        addQuestion() {
            this.survey.questions.push({
                answers: [], type: "BOOLEAN", required: true
            })
        }

        removeQuestion(index) {
            this.survey.questions.splice(index, 1);
        }

        checkAnswers(question) {
            if (question.type !== "CHOICES" && question.type !== "MULTIPLE_CHOICES") question.answers = [];
        }

        addAnswer(question) {
            question.answers.push("");
        }

        removeAnswer(question, index) {
            question.answers.splice(index, 1);
        }

        removeSurvey(item, ev) {
            const confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.translate['instant']('confirm_ok'))
                .cancel(this.translate['instant']('confirm_cancel'))
                .title(this.translate['instant']('tablet_survey_remove_confirm', item));

            return this.dialog.show(confirm)
                .then(() => this.executeRemove(item), null)
        }

        executeRemove(item) {
            this.promise = this.http.delete(`${TEMPLATE_URL}${item.id}/`)
                .then(() => {
                    this.items = _.without(this.items, item);
                    this.survey = this.survey.id === item.id ? null : this.survey;
                }, null)
        }

    }

    module.exports = TabletSurveyCtrl;

})();
