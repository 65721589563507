/**
 * Created by BETALOS on 02/01/2017.
 */
(function () {

    'use strict';

    module.exports = DictionarySetupCtrl;

    DictionarySetupCtrl.$inject = ["$scope", "dictionaryService", "$mdToast", "$translate", "$timeout", "$mdDialog"];

    function DictionarySetupCtrl($scope, dictionaryService, $mdToast, $translate, $timeout, $mdDialog) {
        let vm = this;
        let dictArray = [];
        let isFirstTime = true;

        vm.$onInit = init;

        vm.chooseNode = chooseNode;
        vm.handleDictNode = handleDictNode;
        vm.duplicate = duplicate;
        vm.remove = remove;

        vm.createNewDictGroup = createNewDictGroup;
        vm.editDictionaryConfig = _.mnDelay(editDictionaryConfig, 400);

        vm.dictionaryKeys = [];

        function init() {
            vm.keys = ['/', '|', '>', ':'];
            vm.dictKeys = ['\n', '-', ',', ';', '|'];

            vm.dict = dictionaryService.getDictionaryID();
            vm.dictionaryKeys = dictionaryService.getDictionaryGroups();

            chooseNode(_.head(vm.dictionaryKeys));

            $timeout(function () {
                vm.dictionaryConfig = dictionaryService.dictionaryConfig;
            });
        }

        function createNewDictGroup() {
            dictArray = [];
            vm.node = {};
            $scope.dictionarySetup.$setUntouched();
        }

        function chooseNode(item) {
            if (_.isUndefined(item)) {
                vm.node = {};
                return;
            }

            vm.node = dictionaryService.getGroup(item.uid);
            vm.node = _.head(vm.node);

            dictArray = [];
            vm.node.text = prepareGroupText(vm.node.nodes, 0);
        }

        function prepareGroupText(nodes, dep) {
            return _.reduce(nodes, function (sum, item) {
                let value = _.repeat('*', dep) + item.value;

                dictArray.push({
                    node: _.pick(item, ['value', 'uid']),
                    text: value,
                    dep: dep
                });

                sum += value + '\n';
                if (!_.isEmpty(item.nodes)) sum += prepareGroupText(item.nodes, dep + 1);

                return sum;
            }, '');
        }

        function duplicate(item) {
            dictArray = [];
            let node = _.head(dictionaryService.getGroup(item.uid));
            vm.node = _.assign({}, {text: prepareGroupText(node.nodes, 0)})
        }

        function handleDictNode() {
            let nodes = vm.node.text.split('\n');
            let dictionaryNodes = _.reduce(nodes, handleNode, []);

            vm.node.nodes = _.isEmpty(dictionaryNodes) ? [] : constructTree();

            dictionaryService.editDictionaryGroup(vm.node)
                .then(success);

            function success() {
                if (_.has(vm.node, 'uid')) {
                    let index = _.findIndex(vm.dictionaryKeys, _.pick(vm.node, 'uid'))
                    vm.dictionaryKeys.splice(index, 1, _.pick(vm.node, ['value', 'uid']));
                } else {
                    vm.dictionaryKeys = dictionaryService.getDictionaryGroups();
                    vm.node = _.find(vm.dictionaryKeys, _.pick(vm.node, 'value'));

                    vm.node = dictionaryService.getGroup(vm.node.uid);
                    vm.node = _.head(vm.node);
                    vm.node.text = prepareGroupText(vm.node.nodes, 0);
                }

                let toast = simpleToast();

                $timeout(function () {
                    $mdToast.show(toast);
                }, 100);
            }


            function handleNode(nodes, node) {
                if (_.isEmpty(node)) return nodes;

                let existence = _.find(dictArray, {text: node});
                let dep = node.match(/^(\*+)/g);
                let value = _.replace(node, /^(\*+)/g, '');

                dep = _.isNull(dep) ? 0 : _.head(dep).length;

                let dictionaryNode = _.isNil(existence) ? {
                    text: node,
                    dep: dep,
                    node: {value: value}
                } : existence;

                return _.concat(nodes, dictionaryNode);
            }

            function constructTree() {
                let nodes = [];
                let currentLoop, nextDep;

                do {
                    currentLoop = _.chain(dictionaryNodes).shift().cloneDeep().value();

                    nodes.push(currentLoop.node);

                    nextDep = _.chain(dictionaryNodes).get('0.dep', 0).value();

                    if (currentLoop.dep < nextDep) {
                        currentLoop.node.nodes = constructTree();
                        nextDep = _.chain(dictionaryNodes).get('0.dep', 0).value();
                    }

                } while (!_.isEmpty(dictionaryNodes) && currentLoop.dep <= nextDep);

                return nodes;
            }
        }

        function remove(item, ev) {
            let confirm = $mdDialog.confirm()
                .title($translate['instant']('dictionary_remove_confirm', {title: item.value}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'));

            $mdDialog.show(confirm).then(removeConfirm);

            function removeConfirm() {
                let node = _.chain(dictionaryService.getGroup(item.uid)).head().assign({is_deleted: true}).value();
                dictionaryService.editDictionaryGroup(node).then(removeDone);
            }

            function removeDone() {
                vm.dictionaryKeys = dictionaryService.getDictionaryGroups();
                if (_.eq(item.uid, vm.node.uid)) chooseNode(vm.dictionaryKeys[0]);
            }
        }

        function editDictionaryConfig() {
            if (isFirstTime) isFirstTime = false;
            else dictionaryService.editDictionaryConfig(vm.dictionaryConfig)
                .then(success);

            function success() {
                let toast = simpleToast();

                $timeout(function () {
                    $mdToast.show(toast);
                }, 100);
            }
        }

        function simpleToast() {
            return $mdToast.simple()
                .textContent($translate['instant']('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
        }

    }

})();
